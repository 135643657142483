import { CallToAction } from '@components/magicui/call-to-action'
import { HyperText } from '@components/magicui/hyper-text'
import { MetaboardAnimatedBeam } from '@components/magicui/magic-beam/metaboard-animated-beam'
import { SocialProofTestimonials } from '@components/magicui/testimonials/testimonials'
import { LandingSignUp } from '@components/navigation/landing-sign-up'

<div className='bricolage-grotesque'>
  <CallToAction/>

  **Are You Overwhelmed by Information Overload?**

  Do you ever feel like your mind is constantly racing with ideas, tasks, and responsibilities? You’re not alone. In today’s fast-paced world, it’s easy to feel overwhelmed by the sheer volume of information you need to manage every day. Whether it’s planning projects, brainstorming new ideas, or just keeping track of your daily to-dos, it can be hard to stay organized and focused.

  **The Mental Clutter is Real**:

  - You try to juggle everything in your head, and tasks keep slipping through the cracks.
  - Sticky notes, to-do lists, and scattered documents don’t cut it anymore.
  - The pressure of keeping up with everything causes unnecessary stress, making you feel less productive and more frustrated.

  **But what if you didn’t have to hold all of that in your head?**

  Metaboard is designed to help you offload mental clutter and turn your chaotic thoughts into organized, actionable plans. No more scattered notes, forgotten ideas, or stress from trying to remember everything. With Metaboard, you can visually map out your thoughts, easily manage complex projects, and collaborate seamlessly with others in real-time—all in one place.

  ***It’s time to free up your mind and focus on what really matters.***

  <MetaboardAnimatedBeam />

  ## The Science Behind Visual Knowledge Management

  Research consistently shows that visual thinking tools dramatically improve learning, memory, and problem-solving:

  ### 📊 Proven Benefits

  - **40% Better Learning**: Studies at Harvard and Columbia show visual learning methods improve comprehension by up to 40% compared to text alone [Mayer & Anderson, 1991]
  - **6x Better Recall**: Research published in the Journal of Educational Psychology found people remember information six times better when presented visually versus verbally [Levie & Lentz, 1982]
  - **Visual Processing Speed**: MIT neuroscientists demonstrated our brains can process images in as little as 13 milliseconds [Potter et al., 2014]
  - **Dual Coding Advantage**: Research from the University of Western Ontario showed combining visual and verbal information increases retention by 89% [Paivio & Clark, 1991]
  - **Pattern Recognition**: A Stanford study found visual mapping improved pattern identification by 53% compared to text-based learning [Larkin & Simon, 1987]

  ### 🧪 Why It Works

  - **Working Memory Enhancement**: Research in Cognitive Science shows visual organization reduces cognitive load by up to 32% [Sweller et al., 2011]
  - **Neural Network Activation**: fMRI studies at Stanford showed visual thinking engages both hemispheres of the brain simultaneously [Kosslyn et al., 2001]
  - **Spatial Memory Leverage**: Studies at the Max Planck Institute confirmed humans have superior recall for spatial relationships [Burgess et al., 2002]
  - **Active Processing**: Research in Learning and Instruction demonstrated visual mapping increases depth of processing by 29% [Novak & Cañas, 2008]
  - **Cognitive Offloading**: Studies in Psychological Science showed external visualization tools improve problem-solving efficiency by 44% [Risko & Gilbert, 2016]

  *All statistics cited from peer-reviewed academic journals and research institutions*

  ## What is Metaboard for?

  - **Brainstorm Visually**: Turn your ideas into beautiful mind maps on an infinite canvas
  - **Master Complex Projects**: Break big goals into bite-sized pieces you can actually tackle
  - **Team Up in Real-Time**: Collaborate live with your team - it's like Google Docs meets mind mapping
  - **Clear Your Mind**: Stop trying to remember everything - let Metaboard be your second brain
  - **Connect the Dots**: Link your notes, research, and ideas in ways that make sense to you
  - **Nail Your Presentations**: Build engaging stories from scattered thoughts
  - **Learn Faster**: Master new topics by mapping them out visually
  - **Make Better Decisions**: See all your options laid out clearly before you choose
  - **Create with Confidence**: Plan your content and campaigns with a bird's eye view
  - **Run Better Meetings**: Turn boring sessions into engaging visual discussions

  <div className='flex justify-center mt-12'>
    <HyperText
      className="text-4xl font-bold text-black dark:text-white"
      text="Turn chaos into clarity with Metaboard"
    />
  </div>

  ## Your Knowledge Management Journey

  Whatever path you choose to organize your thoughts and ideas, Metaboard supports your learning journey.

  Our app works for many different knowledge management systems:

  ### 🧠 Building a Second Brain
  Follow Tiago Forte's proven CODE methodology:
  - **Capture** information from any source
  - **Organize** your knowledge meaningfully
  - **Distill** insights to their essence
  - **Express** ideas in your own words


  ### 📚 Zettelkasten Method
  Embrace the power of atomic notes and connections:
  - Create bite-sized, interconnected ideas
  - Build a network of knowledge
  - Let insights emerge naturally
  - Develop ideas over time

  ### 📋 PARA Method
  Structure your digital world intuitively:
  - **Projects** for active goals
  - **Areas** for ongoing responsibilities
  - **Resources** for future reference
  - **Archives** for completed work

  Whether you're just starting your personal knowledge management journey or refining your existing system, Metaboard adapts to your preferred method.

  **Your second brain should work the way you think.**

  *Metaboard is not associated with Tiago Forte or his company, but we share the same vision of helping people build a second brain.*


  <SocialProofTestimonials/>

  <div className='mb-8 mt-16 flex items-center justify-center'>
    <LandingSignUp/>
  </div>
</div>