import { DefaultContactField } from '@/common/constants/boards'
import { useClickOutside } from '@components/boards/hooks/use-click-outside'
import { isEmail, isUrl } from '@components/boards/utils/is-url'
import { Dropdown } from '@components/dropdown/dropdown'
import { RawInputField } from '@components/fields/input-field'
import { NavButton } from '@components/nav-button'
import { FormGroup } from '@components/ui/forms/form-group'
import { useLocaleMemo } from '@hooks/use-locale-memo'
import { useMergedRefs } from '@hooks/use-merged-refs'
import { t, Trans } from '@lingui/macro'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {
  IconAddressBook,
  IconAt,
  IconBrandFacebook,
  IconBrandGithub,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandSkype,
  IconBrandTelegram,
  IconBrandWhatsapp,
  IconBrandYoutube,
  IconBuilding,
  IconCake,
  IconChevronDown,
  IconPhone,
  IconUser,
  IconX,
} from '@tabler/icons-react'
import { FieldArray, useFormikContext } from 'formik'
import defer from 'lodash/defer'
import React, { useMemo, useState } from 'react'
import { useIMask } from 'react-imask'

export function ContactFields() {
  const { values } = useFormikContext<any>()

  const fieldData = useFieldData()

  return (
    <FormGroup label={t`Contact Info`} className='mb-0'>
      <FieldArray
        name='contactInfo'
        render={(arrayHelpers: any) => {
          function onClick(field: DefaultContactField) {
            arrayHelpers.push({
              field,
              value: '',
            })
          }

          return (
            <>
              <div className='!mb-4 space-y-0.5'>
                {values?.contactInfo?.length > 0
                  ? values.contactInfo.map(
                      ({ field, value }, index: number) => (
                        <ContactField
                          key={index}
                          index={index}
                          field={field}
                          value={value}
                          arrayHelpers={arrayHelpers}
                          fieldData={fieldData}
                        />
                      ),
                    )
                  : null}
              </div>

              <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                  <NavButton icon={IconChevronDown} iconRight>
                    <Trans>Add Field</Trans>
                  </NavButton>
                </DropdownMenu.Trigger>
                <DropdownMenu.Portal>
                  <Dropdown.Content sideOffset={5}>
                    {Object.values(DefaultContactField).map(field => {
                      const { label, icon: Icon } = fieldData[field]

                      return (
                        <Dropdown.Item
                          key={field}
                          onClick={() => onClick(field)}
                          className='flex items-center gap-2 pl-[5px]'
                        >
                          {Icon ? <Icon className='h-4 w-4' /> : null}
                          {label}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Content>
                </DropdownMenu.Portal>
              </DropdownMenu.Root>
            </>
          )
        }}
      />
    </FormGroup>
  )
}

function ContactField({ index, field, value, arrayHelpers, fieldData }) {
  const [editing, setEditing] = useState(false)

  const { label, icon: Icon } = fieldData[field]

  const fieldRef = React.useRef(null)

  useClickOutside(fieldRef, () => setEditing(false))

  const maskOptions = useMemo(() => getMask(field), [field])

  const { ref: maskRef } = useIMask({ ...maskOptions })

  const ref = useMergedRefs(fieldRef, maskRef)

  function edit() {
    setEditing(true)
    defer(() => {
      fieldRef.current.focus()
    })
  }

  return (
    <div key={index} className='flex items-center gap-2'>
      <span className='flex w-32 items-center gap-2 text-sm font-medium'>
        {Icon ? <Icon className='h-4 w-4' /> : null}
        {label}
      </span>
      {editing ? (
        <RawInputField
          name={`contactInfo.${index}.value`}
          className='flex-grow'
          ref={ref}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setEditing(false)
            }
          }}
        />
      ) : (
        <span
          className='flex-grow cursor-pointer border border-transparent p-2.5 text-sm leading-[24px]'
          onClick={!value ? edit : undefined}
        >
          {value ? (
            isUrl(value) ? (
              <a
                href={value}
                target='_blank'
                rel='nofollow noreferrer'
                className='hover:underline'
              >
                {value}
              </a>
            ) : isEmail(value) ? (
              <a href={`mailto:${value}`} className='hover:underline'>
                {value}
              </a>
            ) : (
              value
            )
          ) : (
            <span className='opacity-50'>
              <Trans>Empty</Trans>
            </span>
          )}
        </span>
      )}
      {!editing ? (
        <NavButton onClick={edit}>
          <Trans>Edit</Trans>
        </NavButton>
      ) : null}
      <NavButton onClick={() => arrayHelpers.remove(index)}>
        <Trans>Remove</Trans>
      </NavButton>
    </div>
  )
}

const urlMask = {
  mask: 'https://*',
  blocks: {
    '*': {
      mask: /[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]+/,
    },
  },
}

function getMask(field: DefaultContactField) {
  switch (field) {
    case DefaultContactField.WhatsApp:
    case DefaultContactField.Telegram:
    case DefaultContactField.Phone:
      return {
        mask: [
          '0000-0000',
          '00000-0000',
          '(00) 00000-0000',
          '(00) 0000-0000',
          '0 (00) 0000-0000',
          '0 (00) 00000-0000',
          '+00 00 00000-0000',
        ],
      }
    case DefaultContactField.CPF:
      return { mask: '000.000.000-00', lazy: true }
    case DefaultContactField.CNPJ:
      return { mask: '00.000.000/0000-00' }
    case DefaultContactField.RG:
      return { mask: '00.000.000-0' }
    case DefaultContactField.Birthdate:
      return {
        mask: '00/00/0000',
      }
    case DefaultContactField.Website:
    case DefaultContactField.LinkedIn:
    case DefaultContactField.Instagram:
    case DefaultContactField.YouTube:
    case DefaultContactField.Facebook:
    case DefaultContactField.GitHub:
      return urlMask
    default:
      return { mask: '' }
  }
}

export function useFieldData() {
  return useLocaleMemo(() => ({
    [DefaultContactField.Email]: { label: t`Email`, icon: IconAt },
    [DefaultContactField.Phone]: { label: t`Phone`, icon: IconPhone },
    [DefaultContactField.Address]: { label: t`Address`, icon: IconAddressBook },
    [DefaultContactField.CNPJ]: { label: 'CNPJ', icon: IconBuilding },
    [DefaultContactField.CPF]: { label: 'CPF', icon: IconUser },
    [DefaultContactField.RG]: { label: 'RG', icon: IconUser },
    [DefaultContactField.Birthdate]: {
      label: t`Birthdate`,
      icon: IconCake,
    },
    [DefaultContactField.Website]: { label: t`Website`, icon: IconAt },
    [DefaultContactField.LinkedIn]: {
      label: 'LinkedIn',
      icon: IconBrandLinkedin,
    },
    [DefaultContactField.Instagram]: {
      label: 'Instagram',
      icon: IconBrandInstagram,
    },
    [DefaultContactField.X]: { label: 'X', icon: IconX },
    [DefaultContactField.YouTube]: { label: 'YouTube', icon: IconBrandYoutube },
    [DefaultContactField.Facebook]: {
      label: 'Facebook',
      icon: IconBrandFacebook,
    },
    [DefaultContactField.GitHub]: { label: 'GitHub', icon: IconBrandGithub },
    [DefaultContactField.WhatsApp]: {
      label: 'WhatsApp',
      icon: IconBrandWhatsapp,
    },
    [DefaultContactField.Telegram]: {
      label: 'Telegram',
      icon: IconBrandTelegram,
    },
    [DefaultContactField.Skype]: { label: 'Skype', icon: IconBrandSkype },
  }))
}
