import { FunkyButton } from '@/client/components/ui/funky-button'
import { AudioUtils } from '@/client/utils/audio-utils'
import { Trans } from '@lingui/macro'
import { IconPlayerPlay } from '@tabler/icons-react'
import React from 'react'

export function MyDeckLearnButton({
  deckId,
  exerciseCount,
  reviewCount,
  learnedCount,
  className,
}) {
  return (
    <FunkyButton
      variant={reviewCount ? 'warning' : 'primary'}
      to={`/practice/${deckId}`}
      disabled={
        !exerciseCount || // No exercises in deck
        (!reviewCount && learnedCount === exerciseCount) // No reviews and all exercises learned
      }
      className={className}
      onClick={() => {
        AudioUtils.unlock()
      }}
    >
      <IconPlayerPlay className='h-4 w-4' /> <Trans>Practice</Trans>
    </FunkyButton>
  )
}
