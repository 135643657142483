import { useMethod } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { MultiSelect } from '@mantine/core'
import { IconBuilding } from '@tabler/icons-react'
import { useField } from 'formik'
import React, { useMemo } from 'react'
import { z } from 'zod'

type TeamSwitcherProps = {
  value: string[]
  onChange: (value: string[]) => void
  className?: string
  orgId: string
  disabled?: boolean
}

export function TeamSwitcher({
  value,
  onChange,
  className,
  orgId,
  disabled,
}: TeamSwitcherProps) {
  if (!z.array(z.string()).safeParse(value || []).success) {
    throw new Error('value must be an array of strings')
  }

  const { user } = useMetaboardAuth()

  const method = useMethod({
    method: 'team.list',
    params: {
      orgId,
    },
    defaultValue: [],
    deps: [orgId],
  })

  const data = useMemo(() => {
    if (!user) return []

    return method.result.map((org: any) => ({
      label: org.name,
      value: org._id,
    }))
  }, [user, method.result])

  if (!data) return null

  return (
    <MultiSelect
      data={data}
      value={value || []}
      leftSection={<IconBuilding size={16} />}
      onChange={value => {
        onChange(value?.length ? value : null)
      }}
      className={className}
      size='xs'
      searchable
      disabled={disabled}
    />
  )
}

export function TeamSwitcherField(props: {
  name: string
  orgId: string
  disabled?: boolean
}) {
  const [field, , helper] = useField(props.name)
  return (
    <TeamSwitcher
      orgId={props.orgId}
      value={field.value}
      onChange={helper.setValue}
      disabled={props.disabled}
    />
  )
}
