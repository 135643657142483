import { Button } from '@/client/components/ui/button'
import { Trans } from '@lingui/macro'
import { IconLink, IconRelationOneToMany } from '@tabler/icons-react'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

export function ExerciseArticles({ articles, onArticleClick }) {
  if (isEmpty(articles)) return null

  return (
    <div className='mb-6 rounded bg-emerald-500/10 p-4 text-[12px] dark:text-slate-300'>
      <h2 className='mb-2 text-[14px] font-bold'>
        <Trans>Related Articles</Trans>{' '}
        <IconRelationOneToMany className='ml-1 h-4 w-4' />
      </h2>
      {articles.map(article => (
        <Button
          key={article._id}
          icon='document-open'
          onClick={() => onArticleClick(article._id)}
          variant='outline'
        >
          {article.title} <IconLink className='h-4 w-4' />
        </Button>
      ))}
    </div>
  )
}
