import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import React from 'react'

export type Icon = React.ReactNode | React.ComponentType

export function isReactClassComponent(
  component: any,
): component is React.ComponentType<Record<string, any>> {
  return component && isFunction(component.render)
}

export function isReactFunctionComponent(
  component: any,
): component is React.ComponentType<Record<string, any>> {
  return (
    isFunction(component) &&
    String(component).includes('return React.createElement')
  )
}

export function parseIcon(icon: Icon, className?: string) {
  if (!icon) return null

  if (isString(icon)) {
    throw new Error('String icons are not supported')
  }

  if (isReactClassComponent(icon) || isReactFunctionComponent(icon)) {
    return React.createElement(icon, {
      className,
    })
  }

  if (React.isValidElement(icon)) {
    return <span className={className}>{icon}</span>
  }

  return null
}

type Props = {
  icon: Icon
  className?: string
}

export function RenderIcon({ icon, className = 'h-5 w-5' }: Props) {
  return parseIcon(icon, className)
}
