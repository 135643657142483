import { cn } from '@/client/utils/cn'
import { Marquee } from '@components/magicui/marquee'
import { UserTestimonials } from '@components/magicui/testimonials/user-testimonials'
import { StarFilledIcon } from '@radix-ui/react-icons'
import React from 'react'

export interface TestimonialCardProps {
  name: string
  role: string
  img?: string
  description: React.ReactNode
  className?: string
  [key: string]: any
}

export const TestimonialCard = ({
  description,
  name,
  img,
  role,
  className,
  ...props // Capture the rest of the props
}: TestimonialCardProps) => (
  <div
    className={cn(
      'mb-4 flex w-full cursor-pointer break-inside-avoid flex-col items-center justify-between gap-6 rounded-xl p-4',
      // light styles
      ' border border-neutral-200 bg-white',
      // dark styles
      'dark:bg-black dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]',
      className,
    )}
    {...props} // Spread the rest of the props here
  >
    <div className='select-none text-sm font-normal text-neutral-700 dark:text-neutral-400'>
      {description}
      <div className='flex flex-row py-1'>
        <StarFilledIcon className='size-4 text-yellow-500' />
        <StarFilledIcon className='size-4 text-yellow-500' />
        <StarFilledIcon className='size-4 text-yellow-500' />
        <StarFilledIcon className='size-4 text-yellow-500' />
        <StarFilledIcon className='size-4 text-yellow-500' />
      </div>
    </div>

    <div className='flex w-full select-none items-center justify-start gap-5'>
      <img
        src={img}
        className='ring-border h-10 w-10  rounded-full ring-1 ring-offset-4'
      />

      <div>
        <p className='font-medium text-neutral-500'>{name}</p>
        <p className='text-xs font-normal text-neutral-400'>{role}</p>
      </div>
    </div>
  </div>
)

export function SocialProofTestimonials() {
  return (
    <section id='testimonials'>
      <div className='py-14'>
        <div className='container mx-auto'>
          <h3 className='text-center text-sm font-semibold text-gray-500'>
            WHAT USERS ARE SAYING ABOUT METABOARD
          </h3>
          <div className='relative mt-6 max-h-[650px] overflow-hidden'>
            <div className='gap-4 md:columns-2 xl:columns-3'>
              {Array(Math.ceil(UserTestimonials.length / 3))
                .fill(0)
                .map((_, i) => (
                  <Marquee
                    vertical
                    key={i}
                    className={cn({
                      '[--duration:60s]': i === 1,
                      '[--duration:30s]': i === 2,
                      '[--duration:70s]': i === 3,
                    })}
                  >
                    {UserTestimonials.slice(i * 3, (i + 1) * 3).map(
                      (card, idx) => (
                        <TestimonialCard {...card} key={idx} />
                      ),
                    )}
                  </Marquee>
                ))}
            </div>
            <div className='pointer-events-none absolute inset-x-0 bottom-0 h-1/4 w-full bg-gradient-to-t from-gray-200 from-20% dark:from-slate-700'></div>
            <div className='pointer-events-none absolute inset-x-0 top-0 h-1/4 w-full bg-gradient-to-b from-gray-200 from-20% dark:from-slate-700'></div>
          </div>
        </div>
      </div>
    </section>
  )
}
