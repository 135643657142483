import { NavButton } from '@components/nav-button'
import { IconRotate, IconRotateClockwise } from '@tabler/icons-react'
import React from 'react'
import { useBoardOperations } from './hooks/use-board-operations'

export function UndoButton() {
  const { undo, canUndo } = useBoardOperations()

  if (!canUndo) return null

  return (
    <NavButton onClick={undo} className='btn-undo flex space-x-4'>
      <IconRotate className='h-4 w-4' />
    </NavButton>
  )
}

export function RedoButton() {
  const { redo, canRedo } = useBoardOperations()
  if (!canRedo) return null

  return (
    <NavButton onClick={redo} className='btn-redo flex space-x-4'>
      <IconRotateClockwise className='h-4 w-4' />
    </NavButton>
  )
}
