import { StripePromise } from '@/client/utils/stripe'
import { LanguageCode } from '@/common/constants'
import { useCallback } from 'react'
import { useClient } from '../client'
import { useLocale } from './use-locale'

export function useCheckout(successPath = '/', cancelPath = '/') {
  const locale = useLocale()
  const client = useClient()

  return useCallback(
    async (priceId: string) => {
      const stripe = await StripePromise

      const { sessionId } = await client.m.billing.checkout({
        priceId,
        successPath,
        cancelPath,
        language: locale as LanguageCode,
      })

      await stripe.redirectToCheckout({
        sessionId,
      })
    },
    [client, locale],
  )
}
