import { EmailAuthButton } from '@/client/components/auth/email-auth-button'
import { EmailSignInForm } from '@/client/components/forms/email-sign-in-form'
import { BillingPeriods } from '@/common/constants/billing'
import { GoogleAuthButton } from '@components/auth/google-auth-button'
import { HorizontalLoader } from '@components/horizontal-loader'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { useReactive } from '@hooks/use-reactive-local-storage'
import { Trans } from '@lingui/macro'
import React from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'

export default function SignIn() {
  const state = useReactive({
    emailSignIn: false,
    isLoading: false,
  })

  const { plan } = useParams()
  const { authenticated, isPremiumActive } = useMetaboardAuth()

  const isSubscribing = BillingPeriods.includes(plan) && !isPremiumActive

  if (state.isLoading) {
    return <HorizontalLoader />
  }

  if (authenticated && !isSubscribing) {
    return <Redirect to='/' />
  }

  if (state.emailSignIn) {
    return (
      <EmailSignInForm
        state={state}
        onBack={() => {
          state.emailSignIn = false
        }}
      />
    )
  }

  return (
    <div className='flex flex-col items-center gap-4'>
      <div className='mb-4 text-center text-xl'>
        <Trans>Welcome</Trans>
      </div>

      <GoogleAuthButton />
      <EmailAuthButton
        onClick={() => {
          state.emailSignIn = true
        }}
      />

      <div className='mt-4 max-w-[320px] px-4 text-center text-[11px] text-slate-700 dark:text-slate-300'>
        <Trans>
          By clicking “Sign In” you agree to Metaboard’s{' '}
          <Link to='/terms-of-use' className='font-semibold' target='_blank'>
            Terms of Use
          </Link>{' '}
          and acknowledge that Metaboard’s{' '}
          <Link to='/privacy-policy' className='font-semibold' target='_blank'>
            Privacy Policy
          </Link>{' '}
          applies to you.
        </Trans>
      </div>
    </div>
  )
}

export { SignIn }
