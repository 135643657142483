import { useAsyncEffect } from 'ahooks'
import mermaid from 'mermaid'
import React, { useRef, useState } from 'react'

export function MermaidDiagram({ children }: { children: string }) {
  const mermaidRef = useRef(null)
  const [diagram, setDiagram] = useState('')

  useAsyncEffect(async () => {
    const diagram = await mermaid.render('mermaid-diagram', children)
    setDiagram(diagram.svg)
  }, [children])

  return (
    <div
      className='mermaid'
      ref={mermaidRef}
      dangerouslySetInnerHTML={{ __html: diagram }}
    />
  )
}
