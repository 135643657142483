import { BoardNode } from '@/common/constants/boards'
import { javascript } from '@codemirror/lang-javascript'
import { json } from '@codemirror/lang-json'
import { markdown } from '@codemirror/lang-markdown'
import { python } from '@codemirror/lang-python'
import { t } from '@lingui/macro'
import { TextInput } from '@mantine/core'
import { atomone } from '@uiw/codemirror-theme-atomone'
import CodeMirror from '@uiw/react-codemirror'
import { useDebounceFn } from 'ahooks'
import React from 'react'
import { useBoardOperations } from '../../hooks/use-board-operations'
import { useBoardState } from '../../hooks/use-board-state'

function getLang(ext: string) {
  if (ext === 'js') return 'javascript'
  if (ext === 'jsx') return 'javascript'
  if (ext === 'ts') return 'typescript'
  if (ext === 'tsx') return 'typescript'
  if (ext === 'py') return 'python'
  if (ext === 'json') return 'json'
  if (ext === 'md') return 'markdown'
  return undefined
}

export function NodeCode({ node }: { node: BoardNode<string> }) {
  const operations = useBoardOperations()

  const { isReadOnly } = useBoardState()

  const handleNameChange = useDebounceFn(
    (value: string) => {
      operations.updateNode(node._id, { name: value })
    },
    { wait: 250, trailing: true },
  )

  const handleChange = useDebounceFn(
    (value: string) => {
      operations.updateNode(node._id, { code: value })
    },
    { wait: 250, trailing: true },
  )

  const ext = node.name.split('.').pop()

  return (
    <div className='p-4'>
      <TextInput
        label={t`Filename`}
        defaultValue={node.name}
        onChange={e => {
          handleNameChange.run(e.target.value)
        }}
        className='mb-4'
        disabled={isReadOnly}
      />

      <CodeMirror
        lang={getLang(ext)}
        value={node.code}
        theme={atomone}
        extensions={[
          javascript({ jsx: true, typescript: true }),
          python(),
          json(),
          markdown(),
        ]}
        style={{ fontSize: '11px' }}
        onChange={handleChange.run}
        editable={!isReadOnly}
      />
    </div>
  )
}
