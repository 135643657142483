import { Board } from '@/common/constants/boards'
import { BoardCard } from '@components/boards/grid/board-card'
import { MasonryGrid } from '@components/masonry/masonry-grid'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { useOrgData } from '@hooks/use-org-data'
import React from 'react'

type BoardGridProps = {
  boards: Board[]
  onClick?: (board: Board) => void
}

export function BoardGrid({ boards, onClick }: BoardGridProps) {
  const { userId } = useMetaboardAuth()

  const { teamById, orgById } = useOrgData()

  return (
    <MasonryGrid
      rowHeight={160}
      items={boards}
      render={(board: Board) => (
        <BoardCard
          key={board._id.toString()}
          board={board}
          orgById={orgById}
          teamById={teamById}
          userId={userId}
          onClick={onClick}
        />
      )}
    />
  )
}
