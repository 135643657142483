import { ClientBoardNodeCommentCollection } from '@/client/data'
import { cn } from '@/client/utils/cn'
import { useCount } from '@helenejs/react'
import { IconMessages } from '@tabler/icons-react'
import React from 'react'

export function NodeCommentBadge({ node }) {
  const comments = useCount(ClientBoardNodeCommentCollection, {
    board: node?.board,
    node: node?._id,
  })

  return comments ? (
    <span
      className={cn(
        'absolute left-0 top-0 flex items-center gap-1 px-1.5 py-1 text-left text-[8px] font-medium',
        'rounded-br rounded-tl bg-black/15 text-white transition-all',
      )}
    >
      <IconMessages size={12} />
      {comments}
    </span>
  ) : null
}
