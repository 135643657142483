import { Markdown } from '@/client/components/markdown'
import { BoardNode } from '@/common/constants/boards'
import { markdown, markdownLanguage } from '@codemirror/lang-markdown'
import { languages } from '@codemirror/language-data'
import { Trans } from '@lingui/macro'
import { Button } from '@mantine/core'
import { IconEdit } from '@tabler/icons-react'
import { atomone } from '@uiw/codemirror-theme-atomone'
import ReactCodeMirror from '@uiw/react-codemirror'
import { useDebounceFn } from 'ahooks'
import React, { useState } from 'react'
import { useBoardOperations } from '../../hooks/use-board-operations'
import { useBoardState } from '../../hooks/use-board-state'
import { unescapeHtml } from '../../utils/unescape-html'

export function NodeMarkdown({ node }: { node: BoardNode<string> }) {
  const { isWritable } = useBoardState()
  const [editing, setEditing] = useState(false)
  const operations = useBoardOperations()

  const updateNode = useDebounceFn(
    (value: string) => {
      /**
       * @todo: make sure user is not able to edit read only nodes
       */
      operations.updateNode(node._id, { content: value })
    },
    { wait: 1000 },
  )

  if (editing) {
    return (
      <div className='p-4'>
        <ReactCodeMirror
          value={unescapeHtml(node.content ?? '')}
          onChange={updateNode.run}
          extensions={[
            markdown({
              base: markdownLanguage,
              codeLanguages: languages as any,
            }),
          ]}
          theme={atomone}
          style={{ fontSize: '11px' }}
        />
        <div className='mt-4 flex justify-end'>
          <Button size='xs' variant='light' onClick={() => setEditing(false)}>
            <Trans>Done</Trans>
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className='p-4'>
      {isWritable && (
        <div className='mb-4 flex justify-end'>
          <Button
            size='xs'
            variant='light'
            onClick={() => setEditing(true)}
            leftSection={<IconEdit size={16} />}
          >
            <Trans>Edit</Trans>
          </Button>
        </div>
      )}
      <Markdown>{unescapeHtml(node.content ?? '')}</Markdown>
    </div>
  )
}
