import { cn } from '@/client/utils/cn'
import { useTheme } from '@hooks/use-theme'
import { Button, CopyButton } from '@mantine/core'
import { IconClipboardCheck, IconCopy } from '@tabler/icons-react'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism'

import '@/client/assets/markdown-dark.scss'
import '@/client/assets/markdown-light.scss'
import { MermaidDiagram } from './mermaid'

type Props = {
  children: string
  className?: string
}

export function Markdown({ children, className }: Props) {
  const theme = useTheme()

  const MarkdownComponents = {
    pre({ children }) {
      return <pre className='relative !p-0'>{children}</pre>
    },

    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || '')
      const language = match ? match[1] : ''

      if (language === 'mermaid') {
        return <MermaidDiagram>{String(children)}</MermaidDiagram>
      }

      return !inline && language ? (
        <div className='relative'>
          <SyntaxHighlighter
            language={language}
            PreTag='div'
            style={oneDark}
            className='!m-0'
            {...props}
          >
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>

          <CopyButton value={String(children).trim()}>
            {({ copied, copy }) => (
              <Button
                color='gray'
                onClick={copy}
                className='absolute right-1 top-1'
                variant='transparent'
              >
                {copied ? (
                  <IconClipboardCheck size={16} />
                ) : (
                  <IconCopy size={16} />
                )}
              </Button>
            )}
          </CopyButton>
        </div>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      )
    },
  }

  return (
    <ReactMarkdown
      components={MarkdownComponents}
      className={cn(
        'markdown max-w-full',
        {
          'markdown-dark': theme.isDark,
          'markdown-light': theme.isLight,
        },
        className,
      )}
    >
      {children}
    </ReactMarkdown>
  )
}
