import { MetaboardEvent } from '@/common/events'
import { Client } from '@helenejs/client'
import { Collection } from '@helenejs/data'
import omit from 'lodash/omit'

type Data = Record<string, any>

enum OperationType {
  Insert = 'insert',
  Update = 'update',
  Delete = 'delete',
}

type HeleneCall = {
  method: string
  params: Record<string, any>
}

type Operation = {
  coll: Collection
  type: OperationType
  undo: Data | null
  redo: Data | null

  undoCall: HeleneCall
  redoCall: HeleneCall
}

export class StateManager {
  client: Client

  undoStack: Operation[]
  redoStack: Operation[]

  constructor(client: Client) {
    this.client = client
    this.undoStack = []
    this.redoStack = []
  }

  undo() {
    if (this.undoStack.length === 0) return

    const operation = this.undoStack.pop()

    this.redoStack.push(operation)

    if (operation.type === OperationType.Insert) {
      operation.coll.deleteOne({ _id: operation.redo._id })
    }

    if (operation.type === OperationType.Update) {
      operation.coll.updateOne(
        { _id: operation.undo._id },
        omit(operation.undo, '_id'),
      )
    }

    if (operation.type === OperationType.Delete) {
      operation.coll.insert(operation.undo)
    }

    this.client.call(operation.undoCall.method, operation.undoCall.params)

    this.client.emit(MetaboardEvent.STATE_CHANGED)
  }

  redo() {
    if (this.redoStack.length === 0) return

    const operation = this.redoStack.pop()

    this.undoStack.push(operation)

    if (operation.type === OperationType.Insert) {
      operation.coll.insert(operation.redo)
    }

    if (operation.type === OperationType.Update) {
      operation.coll.updateOne(
        { _id: operation.redo._id },
        omit(operation.redo, '_id'),
      )
    }

    if (operation.type === OperationType.Delete) {
      operation.coll.deleteOne({ _id: operation.undo._id })
    }

    this.client.call(operation.redoCall.method, operation.redoCall.params)

    this.client.emit(MetaboardEvent.STATE_CHANGED)
  }

  push(operation: Operation) {
    this.undoStack.push(operation)
    this.redoStack = []
    this.client.emit(MetaboardEvent.STATE_CHANGED)
  }

  insert(
    coll: Collection,
    data: Data,
    undoCall: HeleneCall,
    redoCall: HeleneCall,
  ) {
    this.push({
      coll,
      type: OperationType.Insert,
      undo: null,
      redo: data,
      undoCall,
      redoCall,
    })
  }

  update(
    coll: Collection,
    oldData: Data,
    newData: Data,
    undoCall: HeleneCall,
    redoCall: HeleneCall,
  ) {
    this.push({
      coll,
      type: OperationType.Update,
      undo: oldData,
      redo: newData,
      undoCall,
      redoCall,
    })
  }

  delete(
    coll: Collection,
    oldData: Data,
    undoCall: HeleneCall,
    redoCall: HeleneCall,
  ) {
    this.push({
      coll,
      type: OperationType.Delete,
      undo: oldData,
      redo: null,
      undoCall,
      redoCall,
    })
  }
}
