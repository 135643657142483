import { UrlHelper } from '@/common/url-helper'
import { AnimatedCircuit } from '@components/animated-circuit'
import { DeckSection } from '@components/decks/deck-section'
import { EnrollButton } from '@components/decks/enroll-button'
import DeleteConfirmation from '@components/delete-confirmation'
import { TimedLoader } from '@components/timed-loader'
import { FunkyButton } from '@components/ui/funky-button'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@components/ui/tabs'
import { useMethod } from '@helenejs/react'
import { UsersIcon } from '@heroicons/react/24/solid'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t, Trans } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router-dom'

export default function Deck() {
  const { deckSlug } = useParams()

  const { user, client } = useMetaboardAuth()

  const history = useHistory()

  const { result } = useMethod({
    method: 'deck.getWithMetadata',
    params: { deckSlug },
    deps: [user],
  })

  const [isConfirmingDelete, setConfirmingDelete] = useState(false)

  if (!result) return <TimedLoader />

  const { deck, metadata, sections } = result

  const {
    _id: deckId,
    title,
    image,
    description,
    enrollmentCount,
    article,
    authorName,
  } = deck

  const helmedMetaTags = [
    { name: 'description', content: description },
    { name: 'author', content: authorName },
    { name: 'google', content: 'nositelinkssearchbox' },
    { name: 'google', content: 'notranslate' },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
    { property: 'og:url', content: location.href },
    { property: 'og:site_name', content: 'Metaboard' },
    image
      ? {
          name: 'og:image',
          content: `${UrlHelper.getImageUrl(image)}?width=800&height=418`,
        }
      : null,
  ]

  return (
    <>
      <Helmet>
        <title>{title} | Metaboard</title>
        {helmedMetaTags.map((tag, index) => {
          if (!tag) return null
          return <meta key={index} {...tag} />
        })}
      </Helmet>
      <figure className='relative mb-4 aspect-[12/4] overflow-hidden bg-indigo-500 lg:aspect-[12/2.5]'>
        <div className='h-full w-full object-cover before:absolute before:inset-0 before:bg-gradient-to-t before:from-black/75 before:content-[""]'>
          {image ? (
            <img
              className='h-full w-full object-cover'
              src={UrlHelper.getUrlWithOptions(UrlHelper.getImageUrl(image), {
                width: 1920,
                height: 480,
                quality: 90,
              })}
              alt={title}
            />
          ) : (
            <AnimatedCircuit />
          )}
        </div>

        <div className='absolute bottom-0 flex w-full items-center gap-2'>
          <h2
            className='mx-auto mb-4 max-w-5xl grow truncate px-4 text-lg text-white lg:text-2xl'
            title={title}
          >
            {title}
          </h2>
        </div>
      </figure>
      <article className='mx-auto max-w-5xl p-4'>
        <Tabs defaultValue='info'>
          <TabsList>
            <TabsTrigger value='info'>
              <Trans>Info</Trans>
            </TabsTrigger>
            <TabsTrigger value='mindmap'>
              <Trans>Mind Map</Trans>
            </TabsTrigger>
            <TabsTrigger value='flashcards'>
              <Trans>Flashcards</Trans>
            </TabsTrigger>
          </TabsList>
          <TabsContent value='info'>
            <div className=' mb-12 grid  grid-cols-1 gap-4 lg:grid-cols-12'>
              {article ? (
                <div
                  className='prose col-span-8'
                  dangerouslySetInnerHTML={{ __html: article }}
                />
              ) : (
                <div
                  className='prose col-span-8'
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
              <div className='col-span-4'>
                <div className='mb-4 flex items-center gap-4'>
                  <EnrollButton
                    isDeckEnrolled={metadata?.isDeckEnrolled}
                    deckId={deckId}
                  />
                  {enrollmentCount ? (
                    <div className='flex items-center gap-1.5'>
                      <UsersIcon className='h-4 w-4' /> {enrollmentCount}
                    </div>
                  ) : null}
                </div>
                {metadata.isAuthor || user?.isAdmin ? (
                  <>
                    <h3 className='mb-4 border-b-[1px] border-b-gray-300 text-sm uppercase text-gray-400 dark:border-b-gray-600 dark:text-gray-500'>
                      <Trans>Manage</Trans>
                    </h3>
                    <div className='mb-4 flex items-center gap-4'>
                      <FunkyButton
                        variant='info'
                        to={`/decks/editor/${deckId}`}
                      >
                        <Trans>Edit Deck</Trans>
                      </FunkyButton>
                      <FunkyButton
                        variant='danger'
                        onClick={() => setConfirmingDelete(true)}
                      >
                        <Trans>Delete Deck</Trans>
                      </FunkyButton>
                      <DeleteConfirmation
                        title={t`Delete Deck`}
                        message={t`Are you sure you want to delete this deck?`}
                        isOpen={isConfirmingDelete}
                        onConfirm={async () => {
                          await client.call('deck.delete', { deckId: deck._id })
                          setConfirmingDelete(false)
                          history.push('/decks')
                        }}
                        onCancel={() => setConfirmingDelete(false)}
                        onClose={() => setConfirmingDelete(false)}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </TabsContent>
          <TabsContent value='flashcards'>
            {!isEmpty(sections) ? (
              <div className='mt-4 min-w-0'>
                {sections?.map((section, index) => (
                  <DeckSection
                    key={section._id}
                    section={section}
                    index={index}
                  />
                ))}
              </div>
            ) : (
              <div className='prose'>
                <Trans>Oops! No flashcards</Trans>
              </div>
            )}
          </TabsContent>
        </Tabs>
      </article>
    </>
  )
}

Deck.layoutProps = {
  noSpacing: true,
}
