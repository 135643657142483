import { isTouchDevice } from '@/client/utils/environment'
import { BoardEvent, NodeType } from '@/common/constants/boards'
import { useClient } from '@helenejs/react'
import interact from 'interactjs'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useBoardState } from './use-board-state'

export function useInteractionForNode({
  id,
  nodeElem,
  viewportPositionRef,
  zoomMultiplier,
  mayMoveNode,
  setDragging,
  node,
  editing,
}) {
  const client = useClient()
  const history = useHistory()
  const { isReadOnly } = useBoardState()

  useEffect(() => {
    if (editing) return
    if (!nodeElem) return

    let startPos = { x: 0, y: 0 }
    let disableTap = false

    const interactable = interact(nodeElem)
      .draggable({
        ignoreFrom: '[data-resize-handle]',
        cursorChecker: () => '',
        onstart(e) {
          if (isReadOnly) return
          if (e.shiftKey) return
          window._isDragging = true

          startPos = e.client

          client.emit(BoardEvent.GroupStart, { id })

          if (!isTouchDevice()) {
            setDragging(true)
            disableTap = true
          }
        },
        onend() {
          window._isDragging = false

          client.emit(BoardEvent.GroupEnd, { id })

          setDragging(false)
        },
        onmove(event) {
          if (isReadOnly) return
          if (!window._isDragging) return

          const x = (event.client.x - startPos.x) / zoomMultiplier
          const y = (event.client.y - startPos.y) / zoomMultiplier

          // If on touch device then move the viewport
          if (isTouchDevice() && !mayMoveNode.current) {
            viewportPositionRef.current.x += event.dx / zoomMultiplier
            viewportPositionRef.current.y += event.dy / zoomMultiplier

            client.emit(
              BoardEvent.SetViewportPosition,
              viewportPositionRef.current,
            )
            return
          }

          client.emit(BoardEvent.GroupMove, {
            id,
            x,
            y,
          })
        },
        inertia: true,
      })
      .on('hold', () => {
        disableTap = true
      })
      .on('doubletap', () => {
        client.emit(BoardEvent.StartRenaming, { id })
      })
      .on(isTouchDevice() ? 'tap' : 'click', () => {
        if (disableTap) {
          disableTap = false
          return
        }

        if (node.type === NodeType.URL && node.url) {
          window.open(node.url as string, '_blank')
          return
        }

        if (node.type === NodeType.Flashcards && node.deck) {
          history.push(`/practice/${node.deck}`)
          return
        }

        if (node.type === NodeType.Board && node.boardTarget) {
          history.push(`/b/${node.boardTarget}`)
          return
        }

        client.emit(BoardEvent.OpenNode, id)
        return
      })

    return () => {
      interactable.unset()
    }
  }, [
    nodeElem,
    zoomMultiplier,
    node.type,
    node.url,
    node.deck,
    node.boardTarget,
    editing,
    isReadOnly,
  ])
}
