import { isDevelopmentOrStaging } from '@/client/utils/constants'
import { StripePrice } from '@/common/constants/billing'
import { MetaboardEvent } from '@/common/events'
import { RainbowButton } from '@components/magicui/rainbow-button'
import { Button } from '@components/shadcn/button'
import { useClient } from '@helenejs/react'
import { useCheckout } from '@hooks/use-checkout'
import { Trans } from '@lingui/macro'
import { motion } from 'framer-motion'
import React from 'react'

export function CallToAction() {
  const client = useClient()
  const redirectToCheckout = useCheckout('/subscribed')

  return (
    <section id='cta'>
      <div className='container px-4 py-12 md:px-6 md:py-24 lg:py-32'>
        {/* <div className='text-center'>
          <HeroPill />
        </div> */}
        <div className='mx-auto space-y-4 py-6 text-center'>
          <h4 className='m-0 mx-auto mb-2 max-w-3xl text-balance text-[42px] font-medium tracking-tighter'>
            <Trans>Map Your Ideas, Clear Your Mind</Trans>
          </h4>
        </div>
        <div className='space-y-4 text-center'>
          <p className='text-muted-foreground m-0 mx-auto max-w-[700px] text-balance md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed'>
            <Trans>
              Turn chaos into clarity with visual brainstorming that just flows
            </Trans>
          </p>
          <div className='mt-6 flex flex-col justify-center gap-4 sm:flex-row'>
            <RainbowButton
              onClick={() => {
                redirectToCheckout(
                  isDevelopmentOrStaging
                    ? StripePrice.Monthly.Test
                    : StripePrice.Monthly.Production,
                )
              }}
            >
              <Trans>Try Premium</Trans>
            </RainbowButton>
            <Button
              onClick={() => {
                client.emit(MetaboardEvent.SIGN_IN)
              }}
            >
              <Trans>Start for Free</Trans>
            </Button>
          </div>

          <motion.p
            className='text-muted-foreground mt-5 text-sm'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.0, duration: 0.8 }}
          >
            <Trans>7-day free trial</Trans>
          </motion.p>
        </div>
      </div>
    </section>
  )
}
