import { BoardNode } from '@/common/constants/boards'
import { useImageUrl } from '@components/boards/hooks/use-image-url'
import { t } from '@lingui/macro'
import { notifications } from '@mantine/notifications'
import React, { useEffect, useMemo, useState } from 'react'

export function NodeImage({ node }: { node: BoardNode<string> }) {
  const [container, setContainer] = useState(null)
  const [image, setImage] = useState(null)

  const imageUrl = useImageUrl(node)

  useEffect(() => {
    const img = new window.Image()
    img.src = imageUrl
    img.onload = () => setImage(img)
  }, [imageUrl])

  const scale = useMemo(() => {
    if (!image) return 0
    if (!container) return 0

    const scale = container?.clientWidth / image.width

    if (scale >= 1) return 1

    return scale
  }, [container?.clientWidth, image?.width])

  if (!node) return null
  if (!image) return null

  return (
    <div
      ref={setContainer}
      className='relative'
      style={{ height: image.height * scale }}
    >
      <img src={imageUrl} alt={node.name} className='absolute inset-0' />

      {scale &&
        node.textDetections
          .filter(d => !d.ParentId)
          .map((text, index) => (
            <React.Fragment key={index}>
              <span
                className='block cursor-pointer rounded border border-red-500 hover:border-red-600'
                style={{
                  position: 'absolute',
                  left: text.Geometry.BoundingBox.Left * image.width * scale,
                  top: text.Geometry.BoundingBox.Top * image.height * scale,
                  width: text.Geometry.BoundingBox.Width * image.width * scale,
                  height:
                    text.Geometry.BoundingBox.Height * image.height * scale,
                }}
                onClick={() => {
                  navigator.clipboard.writeText(text.Text).catch(console.error)

                  notifications.show({
                    message: t`Copied to clipboard`,
                    color: 'green',
                  })
                }}
              />
            </React.Fragment>
          ))}
    </div>
  )
}
