import { cn } from '@/client/utils/cn'
import { RenderIcon } from '@/client/utils/parse-icon'
import { LucideLoaderCircle } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

type Variants =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'warning'
  | 'info'
  | 'fb'
  | 'indigo'

type Props = {
  children: React.ReactNode
  className?: string
  loading?: boolean
  fill?: boolean
  variant?: Variants
  to?: string
  icon?: any
  disabled?: boolean
} & React.HTMLAttributes<HTMLButtonElement | HTMLAnchorElement>

export function FunkyButton({
  children,
  className,
  loading,
  fill,
  variant,
  icon,
  disabled,
  ...props
}: Props) {
  const Component = props.to ? Link : ('button' as any)

  className = cn('bt', className, variant, {
    fill,
  })

  return (
    <Component className={className} disabled={disabled || loading} {...props}>
      <RenderIcon icon={icon} />
      {children}
      {loading ? (
        <LucideLoaderCircle className='ml-1 h-4 w-4 animate-spin' />
      ) : null}
    </Component>
  )
}
