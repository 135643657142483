import { NodeType } from '@/common/constants/boards'
import { NodeAudioPlayer } from '@components/boards/node-audio-player'
import { NodeNameEditor } from '@components/boards/node-name-editor'
import { NodeVoiceRecorderPlayer } from '@components/boards/node-voice-recorder-player'
import { ImageContent } from '@components/boards/nodes/render/image-content'
import { getNodeDimensions } from '@components/boards/utils/nodes'
import { unescapeHtml } from '@components/boards/utils/unescape-html'
import {
  IconBrandYoutube,
  IconCode,
  IconFileTypePdf,
  IconLayoutBoard,
  IconLink,
  IconMarkdown,
  IconMessages,
  IconUser,
} from '@tabler/icons-react'
import defer from 'lodash/defer'
import { LucideFileQuestion } from 'lucide-react'
import React, { useMemo } from 'react'
import ReactPlayer from 'react-player'

export function NodeContentRenderer({ node, label }) {
  switch (node.type) {
    case NodeType.Voice:
      return <NodeVoiceRecorderPlayer node={node} />
    case NodeType.Audio:
      return <NodeAudioPlayer node={node} />
    case NodeType.URL:
      return (
        <IconNodeContent
          icon={node.isYouTubeVideo ? IconBrandYoutube : IconLink}
          label={label}
        />
      )
    case NodeType.AIChat:
      return <IconNodeContent icon={IconMessages} label={label} />
    case NodeType.Board:
      return <IconNodeContent icon={IconLayoutBoard} label={label} />
    case NodeType.Contact:
      return <IconNodeContent icon={IconUser} label={label} />
    case NodeType.PDF:
      return <IconNodeContent icon={IconFileTypePdf} label={label} />
    case NodeType.Image:
      return <ImageContent node={node} />
    case NodeType.Flashcards:
      return <IconNodeContent icon={LucideFileQuestion} label={label} />
    case NodeType.Code:
      return <IconNodeContent icon={IconCode} label={label} />
    case NodeType.Markdown:
      return <IconNodeContent icon={IconMarkdown} label={label} />
    case NodeType.Text:
    default:
      return label
  }
}

export function NodeContent({
  node,
  editing,
  id,
  setSize,
  setEditing,
  rect,
  isDragging,
  isResizing,
}) {
  const unescaped = useMemo(() => unescapeHtml(node.name), [node.name])

  const label = editing ? (
    <NodeNameEditor
      id={id}
      defaultValue={unescaped}
      onDone={() => {
        defer(() => {
          setEditing(false)
        })
      }}
      onSimulation={value => {
        const dimensions = getNodeDimensions(node, value)
        setSize(dimensions)
      }}
      node={node}
    />
  ) : (
    <span className='select-none'>{unescaped}</span>
  )

  if (node.type === NodeType.URL && node.isYouTubeEmbed) {
    if (isDragging || isResizing) return null

    return (
      <ReactPlayer
        url={node.url}
        width={rect.width - 16}
        height={rect.height - 16}
      />
    )
  }

  return <NodeContentRenderer node={node} label={label} />
}

function IconNodeContent({ label, icon: Icon }) {
  return (
    <div className='flex items-center gap-1.5 rounded p-1.5'>
      <Icon className='h-4 w-4 flex-shrink-0 flex-grow-0' />
      {label}
    </div>
  )
}
