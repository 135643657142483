import React from 'react'
import { Highlight } from './highlight'

export const UserTestimonials = [
  {
    name: 'Leonardo V.',
    role: 'Entrepreneur',
    img: '/avatars/leonardo.png',
    description: (
      <p>
        Metaboard can extend your mind in ways you never thought possible!{' '}
        <Highlight>
          You will not only relieve your stress levels by not holding everything
          in your own head, but you will also be able to handle a lot more
          information and build bigger projects than you were able before.
        </Highlight>
      </p>
    ),
  },
  {
    name: 'Paula N.',
    role: 'Consultant',
    img: '/avatars/paula.png',
    description: (
      <p>
        You know when you have a lot of things on your mind and can't organize
        everything? I've used many spreadsheets and apps, but nothing as
        practical and easy as Metaboard.{' '}
        <Highlight>
          I use it for many different areas: finances, studies, course planning,
          and even to create flowcharts for my clients. I'm less stressed
          because I put everything there, I've stopped forgetting things, and
          I've been much more productive.
        </Highlight>{' '}
        It's definitely my second brain.
      </p>
    ),
  },
  {
    name: 'Nacho C.',
    role: 'Full-Stack JavaScript Engineer',
    img: '/avatars/nacho.jpg',
    description: (
      <p>
        Metaboard has changed how I organize my thoughts and information.{' '}
        <Highlight>
          Before, my notes were used to be all over the place and text-based,
          but now I can bring everything into one visual space, which keeps me
          clear on every project or problem.
        </Highlight>{' '}
        What makes Metaboard stand out is its interactive 2D canvas, where
        adding elements, connecting them, and managing resources is simple. This
        flexibility has improved how I approach ideas, making problem-solving
        more visual and organized.
      </p>
    ),
  },
  {
    name: 'Denilson S.',
    role: 'Software Engineer',
    img: '/avatars/denilson.jpg',
    description: (
      <p>
        Metaboard is an awesome tool.{' '}
        <Highlight>
          It's super easy to use, the fast access to an AI chat helps a lot, and
          the fact that you can search any text, even in images, throughout all
          the boards is a nice touch.
        </Highlight>{' '}
        I mostly use Metaboard to play D&D. I organize my character on a board I
        created with all the descriptions for magic, background stories, etc.
        It's super nice to use it to play because I have access to everything at
        once and can see the "big picture." I super recommend it.
      </p>
    ),
  },
  {
    name: 'Bianca F.',
    role: 'Software Engineering Student',
    img: '/profile-picture.png',
    description: (
      <p>
        <Highlight>
          I started using Metaboard to study and noticed it became easier to
          retain information because I was learning and memorizing while
          creating the maps.
        </Highlight>{' '}
        Later, I also began using it to write down my own ideas, which I used to
        do with a notepad, and it became much easier to visualize and organize
        them.
      </p>
    ),
  },
  {
    name: 'Isaque C.',
    role: 'Software Engineer',
    img: '/avatars/isaque.png',
    description: (
      <p>
        <Highlight>
          Metaboard has been the main tool for my studies and work. It helps me
          offload my mind while keeping the information accessible.
        </Highlight>
      </p>
    ),
  },
  {
    name: 'Nathalia N.',
    role: 'Entrepreneur',
    img: '/avatars/nathalia.png',
    description: (
      <p>
        I have an extremely fast-paced mind, and I love pen and paper! But every
        time I wrote everything down, I'd lose the paper!{' '}
        <Highlight>
          Metaboard has helped me a lot because I throw everything I need in
          there, write in my own way, and then organize it later!
        </Highlight>{' '}
        Since I work in several areas, I create different boards and have fun
        connecting them! Plus, it's very easy, interactive, and educational!
      </p>
    ),
  },
]
