import { Item } from '@/client/components/deck-editor/item'
import { ClientExerciseCollection } from '@/client/data'
import { useClient } from '@helenejs/react'
import { t, Trans } from '@lingui/macro'
import {
  IconCircle,
  IconEdit,
  IconGripHorizontal,
  IconTrash,
} from '@tabler/icons-react'
import classnames from 'classnames'
import React from 'react'

export function ExerciseRow({
  exercise,
  setNodeRef,
  listeners,
  attributes,
  style,
  isDragging,
}) {
  const client = useClient()

  return (
    <Item
      ref={setNodeRef}
      style={style}
      className={classnames(
        'group grid grid-cols-1 gap-4 lg:grid-cols-[1fr_160px]',
        {
          'opacity-0': isDragging, // Otherwise, two items are visible when dragging as we are using the overlay pattern
        },
      )}
      data-order={exercise.order}
    >
      <div className='grid grid-cols-1 gap-4 lg:grid-cols-2 lg:flex-row'>
        <div>
          <span className='font-bold'>
            <Trans>Question</Trans>
          </span>
          <div dangerouslySetInnerHTML={{ __html: exercise.question }} />
        </div>
        <div>
          <span className='font-bold'>
            <Trans>Answer</Trans>
          </span>
          <div dangerouslySetInnerHTML={{ __html: exercise.answer }} />
        </div>
      </div>
      <div className='flex flex-row items-center justify-end gap-2'>
        <IconCircle
          className={classnames('h-4 w-4 text-[10px] opacity-0', {
            'opacity-100': exercise._new || exercise._modified,
            'text-green-500': exercise._new,
            'text-yellow-500': exercise._modified,
          })}
          title={exercise._new ? t`New` : t`Modified`}
        />
        <button
          className='btn btn-square btn-ghost btn-sm group-hover:opacity-100 lg:opacity-0'
          onClick={() => {
            client.emit('exercise.edit', exercise)
          }}
        >
          <IconEdit className='h-4 w-4' />
        </button>
        <button
          className='btn btn-square btn-ghost btn-sm group-hover:opacity-100 lg:opacity-0'
          onClick={() => {
            // If the card was only created in the front-end, we don't need to flag it as removed
            if (exercise._new) {
              ClientExerciseCollection.remove({ _id: exercise._id }).catch(
                console.error,
              )
            } else {
              ClientExerciseCollection.update(
                { _id: exercise._id },
                { $set: { removedAt: new Date() } },
              ).catch(console.error)
            }
          }}
        >
          <IconTrash className='h-4 w-4' />
        </button>
        <IconGripHorizontal
          className='h-4 w-4 cursor-grab text-gray-400 group-hover:opacity-100 lg:opacity-0'
          {...listeners}
          {...attributes}
        />
      </div>
    </Item>
  )
}
