import { useTheme } from '@hooks/use-theme'
import { t } from '@lingui/macro'
import { IconMoon, IconSun } from '@tabler/icons-react'
import React from 'react'
import { NavButton } from '../nav-button'

export function ThemeButton() {
  const theme = useTheme()
  return (
    <NavButton onClick={theme.toggle} icon={theme.isLight ? IconSun : IconMoon}>
      {theme.isLight ? t`Light` : t`Dark`}
    </NavButton>
  )
}
