import { useMetaboardAuth } from '@/client/hooks/use-metaboard-auth'
import { cn } from '@/client/utils/cn'
import { BoardPrivacy } from '@/common/constants/boards'
import { MetaboardEvent } from '@/common/events'
import { useClient } from '@helenejs/react'
import { t } from '@lingui/macro'
import { Tooltip } from '@mantine/core'
import {
  IconBox,
  IconExternalLink,
  IconLock,
  IconLockOpen,
  IconUsers,
} from '@tabler/icons-react'
import { LucideBox, LucideBuilding, LucideHandshake } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

function Pill({ children, className = '' }) {
  return (
    <span
      className={cn(
        'absolute bottom-0 left-0 z-30',
        'rounded-md rounded-br-none rounded-tl-none',
        'bg-gray-300/50 p-1 text-xs text-white/80 dark:bg-slate-600/50',
        'flex items-center gap-1.5 font-medium',
        className,
      )}
    >
      {children}
    </span>
  )
}

function OrgPill({ org }) {
  const { user } = useMetaboardAuth()

  return (
    <Pill className='bottom-[unset] left-0 top-0 rounded-bl-none rounded-br-md rounded-tr-none'>
      <LucideBuilding className='h-3 w-3' />
      {org ? org.name : user?.name}
    </Pill>
  )
}

export function BoardCard({ board, teamById, orgById, userId, onClick }) {
  const client = useClient()

  const teamNames =
    board.teams
      ?.map(teamId => teamById[teamId as string]?.name)
      .filter(Boolean) ?? []

  const isExternal =
    board.author !== userId && board.shareWith?.includes(userId)
  const isPublic = board.privacy === BoardPrivacy.Public
  const isShared = board.author === userId && board.shareWith?.length > 0
  const isFromTeam = teamNames?.length > 0
  const isNestedBoard = Boolean(board.node)

  const org = orgById[board.org]

  const Component = (onClick ? 'div' : Link) as any

  return (
    <Component
      to={!onClick ? `/b/${board._id.toString()}${location.hash}` : undefined}
      className={cn(
        'relative flex h-full cursor-pointer items-center justify-center rounded-md bg-slate-700 p-4 text-sm font-semibold shadow transition hover:shadow-md dark:bg-slate-800 dark:text-white',
        'overflow-hidden hover:shadow-xl',
        {
          'border border-violet-500 bg-violet-500 dark:bg-violet-600':
            isNestedBoard,
          'border border-green-500 bg-green-500 dark:bg-green-600': isPublic,
        },
      )}
      onClick={() => {
        client.emit(MetaboardEvent.CLOSE_BOARD_BROWSER)
        onClick?.(board)
      }}
    >
      {board.thumbnail ? (
        <div
          dangerouslySetInnerHTML={{ __html: board.thumbnail }}
          className='[&_svg]:absolute [&_svg]:inset-0 [&_svg]:h-full [&_svg]:w-full [&_svg]:object-contain'
        />
      ) : null}

      <div className='absolute inset-0 bg-slate-700/80 dark:bg-slate-800/80' />

      <span className='z-30 text-center text-white'>{board.name}</span>

      <IconManager
        icons={[
          {
            icon: IconLockOpen,
            tooltip: t`Public`,
            visible: isPublic,
            className: 'text-green-600',
          },
          { icon: IconLock, tooltip: t`Private`, visible: !isPublic },
          { icon: IconBox, tooltip: t`Nested Board`, visible: isNestedBoard },
          {
            icon: IconUsers,
            tooltip: t`Shared (${board.shareWith?.length})`,
            visible: isShared,
          },
          { icon: IconExternalLink, tooltip: t`External`, visible: isExternal },
          {
            icon: LucideHandshake,
            tooltip: t`Teams: ${teamNames.join(', ')}`,
            visible: isFromTeam,
          },
        ]}
      />

      <OrgPill org={org} />

      <Pill className='left-[unset] right-0 rounded-bl-none rounded-tl-md rounded-tr-none'>
        <LucideBox className='h-3 w-3' /> {board.nodeCount ?? 0}
      </Pill>
    </Component>
  )
}

type IIcon = {
  icon: React.ElementType
  tooltip: string
  visible: boolean
  className?: string
  style?: React.CSSProperties
}

function IconTooltip({
  icon: Icon,
  tooltip,
  className = null,
  visible,
  style,
}: IIcon) {
  if (!visible) return null

  return (
    <Tooltip label={tooltip} zIndex={100000} multiline>
      <Icon
        className={cn('absolute right-2 top-2 h-4 w-4 text-white', className)}
        style={style}
      />
    </Tooltip>
  )
}

function IconManager({ icons }: { icons: IIcon[] }) {
  const _icons = icons.filter(i => i.visible)

  return _icons.map((icon, index) => (
    <IconTooltip
      key={index}
      icon={icon.icon}
      tooltip={icon.tooltip}
      visible={icon.visible}
      style={{
        right: `${index * 1.5 + 0.5}rem`,
      }}
    />
  ))
}
