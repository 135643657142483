import { MetaboardEvent } from '@/common/events'
import { Trans } from '@lingui/macro'
import { Button } from '@mantine/core'
import { IconLogin, IconMailCheck } from '@tabler/icons-react'
import React from 'react'
import { useClient } from '../client'

export function SubscriptionSuccess() {
  const client = useClient()

  return (
    <div className='container mx-auto max-w-md py-8'>
      <div className='flex flex-col items-center space-y-6'>
        <IconMailCheck
          size={48}
          color='var(--mantine-color-blue-6)'
          stroke={1.5}
        />

        <h1 className='text-2xl font-bold'>
          <Trans>Thank you for subscribing!</Trans>
        </h1>

        <div className='text-center text-lg'>
          <Trans>
            We've sent you an authentication link via email. Please check your
            inbox and click the link to access your account.
          </Trans>
        </div>

        <div className='text-center text-sm'>
          <Trans>
            Can't find the email? Check your spam folder or contact support if
            you need assistance. Or log in to your account using the button
            below:
          </Trans>
        </div>

        <Button
          onClick={() => client.emit(MetaboardEvent.SIGN_IN)}
          leftSection={<IconLogin size={16} />}
          variant='gradient'
          gradient={{
            from: 'teal',
            to: 'cyan',
          }}
        >
          <Trans>Sign In</Trans>
        </Button>
      </div>
    </div>
  )
}
