import { ClientBoardNodeCollection } from '@/client/data'
import { Point } from '@/client/utils/point'
import { minHeight, minWidth } from '@/common/boards/constraints'
import { BoardEvent, BoardNode, NodeType } from '@/common/constants/boards'
import { BaseColor } from '@/common/constants/color'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { VirtualBounds } from '@components/boards/hooks/use-virtual-bounds'
import { getNodeIdFromEvent } from '@components/boards/utils/get-node-id-from-event'
import { getCenterPoint } from '@components/boards/utils/positioning'
import {
  fromAbsX,
  fromAbsY,
  fromVirtualX,
  fromVirtualY,
} from '@components/boards/utils/virtualization'
import { useObject } from '@helenejs/react'
import { useHeleneEvent } from '@hooks/use-helene-event'
import { useCreation, useEventListener } from 'ahooks'
import { throttle } from 'lodash'
import { useRef, useState } from 'react'

export function useEdges(
  viewportPosition: Point,
  virtualBounds: VirtualBounds,
) {
  const [isDraggingEdge, setDraggingEdge] = useState(false)
  const [sourceNode, setSourceNode] = useState(null)
  const [sourceNodeId, setSourceNodeId] = useState(null)
  const [edgeSourcePosition, setEdgeSourcePosition] = useState({
    x: 0,
    y: 0,
  })
  const [edgeTargetPosition, setEdgeTargetPosition] = useState({ x: 0, y: 0 })
  const edgeTargetPositionRef = useRef({ x: 0, y: 0 })

  const { zoomMultiplier } = useBoardState()

  useHeleneEvent(
    BoardEvent.EdgeStart,
    async ({ nodeId, clientX, clientY }) => {
      const node = (await ClientBoardNodeCollection.findOne({
        _id: nodeId,
      })) as unknown as BoardNode<string>

      const centerPoint = getCenterPoint(node)

      setSourceNode(node)
      setSourceNodeId(nodeId)
      setEdgeSourcePosition({
        x: fromAbsX(centerPoint.x, virtualBounds),
        y: fromAbsY(centerPoint.y, virtualBounds),
      })
      window._isDraggingEdge = true
      setDraggingEdge(true)
      edgeTargetPositionRef.current = {
        x: clientX / zoomMultiplier - viewportPosition.x,
        y: clientY / zoomMultiplier - viewportPosition.y,
      }
      setEdgeTargetPosition(edgeTargetPositionRef.current)
    },
    [viewportPosition],
  )

  const throttledMouseMove = useCreation(
    () =>
      throttle(e => {
        if (!window._isDraggingEdge) return

        const x = e.touches?.[0]?.pageX ?? e.pageX
        const y = e.touches?.[0]?.pageY ?? e.pageY

        edgeTargetPositionRef.current = {
          x: x / zoomMultiplier - viewportPosition.x,
          y: y / zoomMultiplier - viewportPosition.y,
        }
        setEdgeTargetPosition(edgeTargetPositionRef.current)
      }, 10),
    [viewportPosition, zoomMultiplier],
  )

  useEventListener('mousemove', throttledMouseMove, {
    target: document,
  })

  useEventListener('touchmove', throttledMouseMove, {
    target: document,
  })

  const operations = useBoardOperations()

  async function end(e: MouseEvent & TouchEvent) {
    if (!window._isDraggingEdge) return

    let targetNodeId = getNodeIdFromEvent(e)

    if (!targetNodeId) {
      targetNodeId = await operations.addNode(
        fromVirtualX(edgeTargetPositionRef.current.x, virtualBounds) -
          minWidth / 2,
        fromVirtualY(edgeTargetPositionRef.current.y, virtualBounds) -
          minHeight / 2,
        e.metaKey || e.ctrlKey
          ? {
              type: NodeType.AIChat,
              color: BaseColor.Blue,
            }
          : {
              type: NodeType.Text,
            },
      )
    }

    if (targetNodeId && targetNodeId !== sourceNodeId) {
      await operations.addEdge(sourceNodeId, targetNodeId)
    }

    window._isDraggingEdge = false
    setDraggingEdge(false)

    setSourceNodeId(null)
    setEdgeSourcePosition(null)
    setEdgeSourcePosition({ x: 0, y: 0 })
    setEdgeTargetPosition({ x: 0, y: 0 })
    edgeTargetPositionRef.current = { x: 0, y: 0 }
  }

  useEventListener('mouseup', end, {
    target: document,
  })

  useEventListener('touchend', end, {
    target: document,
  })

  return useObject({
    isDraggingEdge,
    sourceNode,
    edgeSourcePosition,
    edgeTargetPosition,
  })
}
