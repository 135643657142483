import { LanguageIcon } from '@/client/utils/flags'
import { LanguageCode } from '@/common/constants'
import { NavButton } from '@components/nav-button'
import { Help } from '@components/navigation/help'
import { useLocaleMemo } from '@hooks/use-locale-memo'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t, Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Menu } from '@mantine/core'
import React from 'react'

export function LanguageMenu() {
  const { client, authenticated } = useMetaboardAuth()
  const { i18n } = useLingui()

  const languageLabelByCode = useLocaleMemo(() => ({
    [LanguageCode.EN]: t`English`,
    [LanguageCode.PT]: t`Portuguese`,
  }))

  const languages = useLocaleMemo(() => [
    {
      label: t`English`,
      value: LanguageCode.EN,
    },
    {
      label: t`Portuguese`,
      value: LanguageCode.PT,
    },
  ])

  return (
    <Menu shadow='md' width={200}>
      <Menu.Target>
        <NavButton>
          <img
            src={LanguageIcon[i18n.locale]}
            className='h-4 w-4'
            alt={languageLabelByCode[i18n.locale]}
          />{' '}
          <span className='hidden md:inline'>
            {languageLabelByCode[i18n.locale]}{' '}
          </span>
          <Help
            label={t`We do our best to detect your language automatically, if it doesn't work you can choose here`}
          />
        </NavButton>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>
          <Trans>Pick a Language</Trans>
        </Menu.Label>

        {languages.map(({ label, value }) => (
          <Menu.Item
            key={value}
            onClick={async () => {
              i18n.activate(value)

              if (authenticated) {
                await client.call('user.update', { language: value })
              }
            }}
            leftSection={
              <img src={LanguageIcon[value]} className='h-4 w-4' alt={label} />
            }
          >
            {label}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}
