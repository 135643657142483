import { cn } from '@/client/utils/cn'
import React from 'react'

export const Highlight = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return (
    <span
      className={cn(
        'bg-cyan-600/20 p-1 py-0.5 font-bold text-cyan-600 dark:bg-cyan-600/20 dark:text-cyan-600',
        className,
      )}
    >
      {children}
    </span>
  )
}
