export enum AIProvider {
  OpenAI = 'openai',
  Anthropic = 'anthropic',
}

export enum AIModel {
  Claude3Opus = 'claude-3-opus-20240229',
  Claude3Sonnet = 'claude-3-sonnet-20240229',
  Claude35Sonnet = 'claude-3-5-sonnet-20241022',
  GPT35Turbo = 'gpt-3.5-turbo',
  GPT35Turbo16k = 'gpt-3.5-turbo-16k',
  GPT4Turbo = 'gpt-4-turbo',
  GPT4O = 'gpt-4o',
}

export const SupportedAIModels = [
  AIModel.Claude3Opus,
  AIModel.Claude3Sonnet,
  AIModel.Claude35Sonnet,
]

export const AIChatModel = {
  [AIModel.Claude3Sonnet]: {
    name: AIModel.Claude3Sonnet,
    description: 'Claude 3 Sonnet',
    provider: AIProvider.Anthropic,
  },
  [AIModel.Claude3Opus]: {
    name: AIModel.Claude3Opus,
    description: 'Claude 3 Opus',
    provider: AIProvider.Anthropic,
  },
  [AIModel.Claude35Sonnet]: {
    name: AIModel.Claude35Sonnet,
    description: 'Claude 3.5 Sonnet',
    provider: AIProvider.Anthropic,
  },
  [AIModel.GPT35Turbo]: {
    name: AIModel.GPT35Turbo,
    description: 'GPT-3.5 Turbo',
    provider: AIProvider.OpenAI,
  },
  [AIModel.GPT35Turbo16k]: {
    name: AIModel.GPT35Turbo16k,
    description: 'GPT-3.5 Turbo 16k',
    provider: AIProvider.OpenAI,
  },
  [AIModel.GPT4Turbo]: {
    name: AIModel.GPT4Turbo,
    description: 'GPT-4 Turbo',
    provider: AIProvider.OpenAI,
  },
  [AIModel.GPT4O]: {
    name: AIModel.GPT4O,
    description: 'GPT-4o',
    provider: AIProvider.OpenAI,
  },
}

export enum AIChatRole {
  User = 'user',
  Assistant = 'assistant',
}

export type AIMessageTextContent = {
  type: 'text'
  text: string
}

export type AIMessageImageContent = {
  type: 'image'
  source: {
    type: 'base64'
    media_type: 'image/jpeg' | 'image/png' | 'image/gif' | 'image/webp'
    data: string
  }
}

export type AnthropicAIChatMessage = {
  role: AIChatRole
  content: (AIMessageTextContent | AIMessageImageContent)[]
  usage?: {
    input_tokens: number
    output_tokens: number
  }
}

export type AIChat = {
  model: AIModel
  messages: AnthropicAIChatMessage[]
}
