import { AudioUtils } from '@/client/utils/audio-utils'
import { cn } from '@/client/utils/cn'
import { fetchAudioBufferWithAuth } from '@/client/utils/fetch-audio-buffer-with-auth'
import { BoardNode } from '@/common/constants/boards'
import { formatDuration } from '@/common/time'
import { useClient } from '@helenejs/react'
import { Trans } from '@lingui/macro'
import { Modal } from '@mantine/core'
import { Button } from 'flowbite-react'
import { LucidePlayCircle, LucideStopCircle } from 'lucide-react'
import React, { useEffect, useMemo, useState } from 'react'

type AudioPlayerProps = {
  node: BoardNode<string>
  field?: string
}

export function NodeAudioPlayer({ node, field = 'm4a' }: AudioPlayerProps) {
  const [isOpen, setOpen] = useState(false)
  const [isPlaying, setPlaying] = useState(false)
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const client = useClient()

  const fileId = node[field]

  const src = useMemo(
    () => (fileId ? `board/${node.board}/audio/${fileId}` : null),
    [fileId],
  )

  useEffect(() => {
    if (!src) return
    fetchAudioBufferWithAuth(client, src)
      .then((audioBuffer: ArrayBuffer) => {
        const localUrl = URL.createObjectURL(new Blob([audioBuffer]))
        const newAudio = new Audio(localUrl)
        setAudio(newAudio)
        newAudio.onloadedmetadata = () => {
          setDuration(newAudio.duration)
        }
      })
      .catch(console.error)
  }, [src])

  useEffect(() => {
    if (!audio) return

    audio.ontimeupdate = () => {
      setCurrentTime(audio.currentTime)
    }

    audio.onended = () => {
      setPlaying(false)
    }

    return () => {
      audio.pause()
      audio.removeAttribute('src')
      audio.load()
    }
  }, [audio])

  const togglePlayStop = async e => {
    e.stopPropagation()

    AudioUtils.unlock()

    if (!audio) return

    if (isPlaying) {
      audio.pause()
    } else {
      audio.play()
    }
    setPlaying(!isPlaying)
  }

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!audio) return
    const newTime = parseFloat(e.target.value)
    audio.currentTime = newTime
    setCurrentTime(newTime)
  }

  const button = isPlaying ? (
    <LucideStopCircle className='h-4 w-4' />
  ) : (
    <LucidePlayCircle className='h-4 w-4' />
  )

  return (
    <div className='relative z-[10000] flex h-full w-full items-center justify-center'>
      <button
        onClickCapture={e => {
          e.preventDefault()
          e.stopPropagation()
          setOpen(true)
        }}
        className={cn(
          'absolute left-[50%] top-[50%] z-[10010] inline-flex h-8 w-8',
          'translate-x-[-50%] translate-y-[-50%] transform items-center',
          'justify-center rounded-full bg-gray-500/30 hover:bg-gray-600',
          'text-white',
        )}
        disabled={!audio}
      >
        <LucidePlayCircle className='h-4 w-4' />
      </button>

      <Modal
        onClose={() => {
          setOpen(false)
        }}
        opened={isOpen}
        title={<Trans>Sign In</Trans>}
        closeOnClickOutside={false}
        size='xl'
      >
        <div className='mt-4 flex gap-4'>
          <input
            type='range'
            min='0'
            max={duration}
            value={currentTime}
            onChange={handleSeek}
            className='w-full'
          />

          <Button
            onClickCapture={togglePlayStop}
            color={isPlaying ? 'red' : 'green'}
            size='sm'
            pill
          >
            {button}
          </Button>
        </div>

        <div className='text-center text-xs font-medium'>
          {formatDuration(currentTime)} of {formatDuration(duration ?? 0)}
        </div>
      </Modal>
    </div>
  )
}
