'use client'

import { cn } from '@/client/utils/cn'
import { AnimatedBeam } from '@components/magicui/magic-beam/magic-beam'
import {
  IconAbc,
  IconBrandYoutube,
  IconInputAi,
  IconLink,
  IconMicrophone,
  IconPhoto,
} from '@tabler/icons-react'
import React, { forwardRef, useRef } from 'react'

const Circle = forwardRef<
  HTMLDivElement,
  { className?: string; children?: React.ReactNode }
>(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'z-10 flex size-12 items-center justify-center rounded-full border-2 bg-white p-3 shadow-[0_0_20px_-12px_rgba(0,0,0,0.8)] dark:border-slate-500/50 dark:bg-slate-500/50',
        className,
      )}
    >
      {children}
    </div>
  )
})

Circle.displayName = 'Circle'

export function MetaboardAnimatedBeam() {
  const containerRef = useRef<HTMLDivElement>(null)
  const div1Ref = useRef<HTMLDivElement>(null)
  const div2Ref = useRef<HTMLDivElement>(null)
  const div3Ref = useRef<HTMLDivElement>(null)
  const div4Ref = useRef<HTMLDivElement>(null)
  const div5Ref = useRef<HTMLDivElement>(null)
  const div6Ref = useRef<HTMLDivElement>(null)
  const div7Ref = useRef<HTMLDivElement>(null)

  return (
    <div
      className='relative flex h-[500px] w-full items-center justify-center overflow-hidden'
      ref={containerRef}
    >
      <div className='flex size-full max-h-[200px] max-w-lg flex-col items-stretch justify-between gap-10'>
        <div className='flex flex-row items-center justify-between'>
          <Circle ref={div1Ref}>
            <IconAbc />
          </Circle>
          <Circle ref={div5Ref}>
            <IconPhoto />
          </Circle>
        </div>
        <div className='flex flex-row items-center justify-between'>
          <Circle ref={div2Ref}>
            <IconMicrophone />
          </Circle>
          <Circle ref={div4Ref} className='size-16'>
            <img src='/favicon.svg' alt='Metaboard' />
          </Circle>
          <Circle ref={div6Ref}>
            <IconBrandYoutube />
          </Circle>
        </div>
        <div className='flex flex-row items-center justify-between'>
          <Circle ref={div3Ref}>
            <IconLink />
          </Circle>
          <Circle ref={div7Ref}>
            <IconInputAi />
          </Circle>
        </div>
      </div>

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div1Ref}
        toRef={div4Ref}
        curvature={-75}
        endYOffset={-10}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div2Ref}
        toRef={div4Ref}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div3Ref}
        toRef={div4Ref}
        curvature={75}
        endYOffset={10}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div5Ref}
        toRef={div4Ref}
        curvature={-75}
        endYOffset={-10}
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div6Ref}
        toRef={div4Ref}
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div7Ref}
        toRef={div4Ref}
        curvature={75}
        endYOffset={10}
        reverse
      />
    </div>
  )
}
