import { CallToAction } from '@components/magicui/call-to-action'
import { HyperText } from '@components/magicui/hyper-text'
import { MetaboardAnimatedBeam } from '@components/magicui/magic-beam/metaboard-animated-beam'
import { SocialProofTestimonials } from '@components/magicui/testimonials/testimonials'
import { LandingSignUp } from '@components/navigation/landing-sign-up'

<CallToAction/>

<div className='flex justify-center'>
  <HyperText
    className="text-4xl font-bold text-black dark:text-white"
    text="Free your mind, reclaim your peace"
  />
</div>


**Are You Overwhelmed by Information Overload?**

Do you ever feel like your mind is constantly racing with ideas, tasks, and responsibilities? You’re not alone. In today’s fast-paced world, it’s easy to feel overwhelmed by the sheer volume of information you need to manage every day. Whether it’s planning projects, brainstorming new ideas, or just keeping track of your daily to-dos, it can be hard to stay organized and focused.

**The Mental Clutter is Real**:

- You try to juggle everything in your head, and tasks keep slipping through the cracks.
- Sticky notes, to-do lists, and scattered documents don’t cut it anymore.
- The pressure of keeping up with everything causes unnecessary stress, making you feel less productive and more frustrated.

**But what if you didn’t have to hold all of that in your head?**

Metaboard is designed to help you offload mental clutter and turn your chaotic thoughts into organized, actionable plans. No more scattered notes, forgotten ideas, or stress from trying to remember everything. With Metaboard, you can visually map out your thoughts, easily manage complex projects, and collaborate seamlessly with others in real-time—all in one place.

***It’s time to free up your mind and focus on what really matters.***

## What is Metaboard for?

- **Visual Brainstorming**: Organize ideas on an infinite canvas, creating mind maps that help you capture and connect your thoughts.
- **Project Management**: Plan and break down complex projects into manageable tasks, timelines, and workflows.
- **Real-Time Collaboration**: Work with teammates or clients in real-time to share ideas, edit documents, and solve problems together.
- **Personal Productivity**: Track your goals, tasks, and to-dos in one organized space, freeing your mind from remembering everything.
- **Information Mapping**: Consolidate scattered notes, research, and data into one visual space for easy reference and understanding.
- **Planning Presentations & Reports**: Map out the structure of your presentations or reports and refine them step-by-step.
- **Learning & Concept Mapping**: Study and master new subjects by creating clear, connected visual outlines of concepts.
- **Decision-Making**: Visually lay out options, pros and cons, and action steps to make informed decisions faster.
- **Content Creation**: Plan blog posts, marketing campaigns, or product launches by organizing resources and ideas in one place.
- **Workshop & Meeting Facilitation**: Engage participants with interactive, real-time visuals during brainstorming sessions or strategy meetings.


<div className='mt-12 text-center'>
  ***All in one place, all in one tool***
</div>

<div className='text-center'>
  ***One tool to rule them all***
</div>

<MetaboardAnimatedBeam />

<SocialProofTestimonials/>

<div className='mb-8 mt-16 flex items-center justify-center'>
  <LandingSignUp/>
</div>
