import { CallToAction } from '@components/magicui/call-to-action'
import { HyperText } from '@components/magicui/hyper-text'
import { MetaboardAnimatedBeam } from '@components/magicui/magic-beam/metaboard-animated-beam'
import { SocialProofTestimonials } from '@components/magicui/testimonials/testimonials'
import { LandingSignUp } from '@components/navigation/landing-sign-up'

<div className='bricolage-grotesque'>
  <CallToAction/>

  **Você está sobrecarregado com excesso de informação?**

  Você já sentiu como se sua mente estivesse constantemente acelerada com ideias, tarefas e responsabilidades? Você não está sozinho. No mundo acelerado de hoje, é fácil se sentir sobrecarregado pelo grande volume de informações que você precisa gerenciar todos os dias. Seja planejando projetos, fazendo brainstorming de novas ideias ou apenas mantendo o controle de suas tarefas diárias, pode ser difícil se manter organizado e focado.

  **A Desordem Mental é Real**:

  - Você tenta fazer malabarismo com tudo na sua cabeça, e as tarefas acabam escapando.
  - Post-its, listas de tarefas e documentos espalhados não são mais suficientes.
  - A pressão de manter tudo em dia causa estresse desnecessário, fazendo você se sentir menos produtivo e mais frustrado.

  **E se você não precisasse guardar tudo isso na sua cabeça?**

  O Metaboard foi projetado para ajudar você a descarregar a desordem mental e transformar seus pensamentos caóticos em planos organizados e acionáveis. Chega de anotações espalhadas, ideias esquecidas ou estresse por tentar lembrar de tudo. Com o Metaboard, você pode mapear visualmente seus pensamentos, gerenciar facilmente projetos complexos e colaborar perfeitamente com outros em tempo real — tudo em um só lugar.

  ***É hora de liberar sua mente e focar no que realmente importa.***

  <MetaboardAnimatedBeam />

  ## A Ciência Por Trás da Gestão Visual do Conhecimento

  Pesquisas consistentemente mostram que ferramentas de pensamento visual melhoram dramaticamente o aprendizado, a memória e a resolução de problemas:

  ### 📊 Benefícios Comprovados

  - **40% Melhor Aprendizado**: Estudos em Harvard e Columbia mostram que métodos de aprendizado visual melhoram a compreensão em até 40% em comparação com texto sozinho [Mayer & Anderson, 1991]
  - **6x Melhor Retenção**: Pesquisas publicadas no Journal of Educational Psychology descobriram que as pessoas lembram informações seis vezes melhor quando apresentadas visualmente versus verbalmente [Levie & Lentz, 1982]
  - **Velocidade de Processamento Visual**: Neurocientistas do MIT demonstraram que nossos cérebros podem processar imagens em apenas 13 milissegundos [Potter et al., 2014]
  - **Vantagem da Codificação Dupla**: Pesquisas da Universidade de Western Ontario mostraram que combinar informação visual e verbal aumenta a retenção em 89% [Paivio & Clark, 1991]
  - **Reconhecimento de Padrões**: Um estudo de Stanford descobriu que o mapeamento visual melhorou a identificação de padrões em 53% em comparação com o aprendizado baseado em texto [Larkin & Simon, 1987]

  ### 🧪 Por Que Funciona

  - **Aprimoramento da Memória de Trabalho**: Pesquisas em Ciência Cognitiva mostram que a organização visual reduz a carga cognitiva em até 32% [Sweller et al., 2011]
  - **Ativação da Rede Neural**: Estudos de fMRI em Stanford mostraram que o pensamento visual engaja ambos os hemisférios do cérebro simultaneamente [Kosslyn et al., 2001]
  - **Alavancagem da Memória Espacial**: Estudos no Instituto Max Planck confirmaram que humanos têm recordação superior para relações espaciais [Burgess et al., 2002]
  - **Processamento Ativo**: Pesquisas em Aprendizado e Instrução demonstraram que o mapeamento visual aumenta a profundidade de processamento em 29% [Novak & Cañas, 2008]
  - **Descarga Cognitiva**: Estudos em Ciência Psicológica mostraram que ferramentas de visualização externa melhoram a eficiência na resolução de problemas em 44% [Risko & Gilbert, 2016]

  *Todas as estatísticas citadas de revistas acadêmicas e instituições de pesquisa revisadas por pares*

  ## Para que serve o Metaboard?

  - **Faça Brainstorm Visualmente**: Transforme suas ideias em belos mapas mentais em uma tela infinita
  - **Domine Projetos Complexos**: Divida grandes objetivos em pedaços menores que você pode realmente realizar
  - **Trabalhe em Equipe em Tempo Real**: Colabore ao vivo com sua equipe - é como o Google Docs encontra o mapeamento mental
  - **Limpe Sua Mente**: Pare de tentar lembrar de tudo - deixe o Metaboard ser seu segundo cérebro
  - **Conecte os Pontos**: Vincule suas anotações, pesquisas e ideias de maneiras que fazem sentido para você
  - **Acerte Suas Apresentações**: Construa histórias envolventes a partir de pensamentos dispersos
  - **Aprenda Mais Rápido**: Domine novos tópicos mapeando-os visualmente
  - **Tome Melhores Decisões**: Veja todas as suas opções claramente antes de escolher
  - **Crie com Confiança**: Planeje seu conteúdo e campanhas com uma visão geral
  - **Conduza Melhores Reuniões**: Transforme sessões chatas em discussões visuais envolventes

  <div className='flex justify-center mt-12'>
    <HyperText
      className="text-4xl font-bold text-black dark:text-white"
      text="Transforme caos em clareza com o Metaboard"
    />
  </div>

  ## Sua Jornada de Gestão do Conhecimento

  Qualquer caminho que você escolha para organizar seus pensamentos e ideias, o Metaboard apoia sua jornada de aprendizado.

  Nosso aplicativo funciona para diversos sistemas de gestão do conhecimento:

  ### 🧠 Construindo um Segundo Cérebro
  Siga a metodologia CODE comprovada de Tiago Forte:
  - **Capture** informações de qualquer fonte
  - **Organize** seu conhecimento de forma significativa
  - **Destile** insights para sua essência
  - **Expresse** ideias em suas próprias palavras

  ### 📚 Método Zettelkasten
  Abrace o poder das notas atômicas e conexões:
  - Crie ideias interconectadas e concisas
  - Construa uma rede de conhecimento
  - Deixe os insights emergirem naturalmente
  - Desenvolva ideias ao longo do tempo

  ### 📋 Método PARA
  Estruture seu mundo digital intuitivamente:
  - **Projetos** para objetivos ativos
  - **Áreas** para responsabilidades contínuas
  - **Recursos** para referência futura
  - **Arquivos** para trabalho concluído

  Seja você esteja apenas começando sua jornada de gestão pessoal do conhecimento ou refinando seu sistema existente, o Metaboard se adapta ao seu método preferido.

  **Seu segundo cérebro deve funcionar do jeito que você pensa.**

  *O Metaboard não está associado a Tiago Forte ou sua empresa, mas compartilhamos a mesma visão de ajudar as pessoas a construírem um segundo cérebro.*

  <SocialProofTestimonials/>

  <div className='mb-8 mt-16 flex items-center justify-center'>
    <LandingSignUp/>
  </div>
</div>