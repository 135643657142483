import { ClientBoardCollection } from '@/client/data'
import { NavButton } from '@components/nav-button'
import { useClient, useFind } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t, Trans } from '@lingui/macro'
import { ObjectId } from 'bson'
import { LucidePlus } from 'lucide-react'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

export function CreateBoardButton() {
  const client = useClient()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const { userId, authenticated, isAdmin, currentWorkspace, isPremiumActive } =
    useMetaboardAuth()

  const boards = useFind(ClientBoardCollection, { author: userId })

  const createBoard = useCallback(async () => {
    setLoading(true)
    const board = await client.call('boards.create', {
      _id: new ObjectId(),
      name: t`New Board`,
      org: ObjectId.isValid(currentWorkspace) ? currentWorkspace : undefined,
    })
    setLoading(false)

    history.push(`/b/${board._id}`)
  }, [currentWorkspace])

  const hasReachedBoardLimit = boards.length >= 3 && !isPremiumActive

  if (!authenticated) return null

  return (
    <NavButton
      icon={LucidePlus}
      onClick={hasReachedBoardLimit ? () => history.push('/plan') : createBoard}
      loading={loading}
      menu={
        isAdmin
          ? [
              {
                label: t`Create Organization`,
                onClick: () => history.push('/org/list#?new-org=true'),
                leftSection: <LucidePlus size={16} />,
              },
            ]
          : null
      }
      collapsible
    >
      <Trans>Create Board</Trans>{' '}
      {!isPremiumActive ? `(${boards.length}/3)` : null}
    </NavButton>
  )
}
