import { InputField } from '@components/fields/input-field'
import { StandardForm } from '@components/forms/standard-form'
import { FormGroup } from '@components/ui/forms/form-group'
import { SwitchField } from '@components/ui/forms/switch-field'
import { useClient } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { SchemaHook } from '@hooks/use-schema'
import { t, Trans } from '@lingui/macro'
import React from 'react'
import { boolean, object } from 'yup'

export default function AccountSettings() {
  const client = useClient()
  const { user } = useMetaboardAuth()

  const nameSchema = SchemaHook.useName()

  if (!user) return null

  return (
    <div>
      <h2>
        <Trans>Account Settings</Trans>
      </h2>

      <StandardForm
        initialValues={user}
        schema={object({
          name: nameSchema,
          emails: object({
            marketing: boolean(),
            newsletter: boolean(),
          }),
        })}
        onSave={async values => {
          await client.call('user.update', values)
        }}
      >
        <FormGroup label={t`Name`}>
          <InputField type='text' name='name' placeholder='Name' />
        </FormGroup>

        <FormGroup label={t`Email`}>
          <InputField
            type='email'
            name='email'
            placeholder={t`Email`}
            disabled
          />
        </FormGroup>

        <h3 className='mb-4 border-b border-b-gray-300/50 pb-2 text-xs font-medium uppercase text-gray-500 dark:border-b-slate-500/50 dark:text-slate-400'>
          <Trans>Privacy Center</Trans>
        </h3>

        <FormGroup label={t`Marketing Emails`}>
          <SwitchField
            name='emails.marketing'
            label={t`Receive marketing emails`}
          />
        </FormGroup>

        <FormGroup label={t`Newsletter`}>
          <SwitchField
            name='emails.newsletter'
            label={t`Receive our newsletter`}
          />
        </FormGroup>
      </StandardForm>
    </div>
  )
}
