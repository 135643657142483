export const MimeType = {
  IMAGE_JPEG: 'image/jpeg',
  IMAGE_PNG: 'image/png',
  IMAGE_GIF: 'image/gif',
  IMAGE_WEBP: 'image/webp',

  APPLICATION_PDF: 'application/pdf',
  TEXT_PLAIN: 'text/plain',

  AUDIO_WAV: 'audio/wav',
  AUDIO_M4A: 'audio/m4a',
}

export const MimeTypeExtension = {
  [MimeType.IMAGE_JPEG]: 'jpg',
  [MimeType.IMAGE_PNG]: 'png',
  [MimeType.IMAGE_GIF]: 'gif',
  [MimeType.IMAGE_WEBP]: 'webp',

  [MimeType.APPLICATION_PDF]: 'pdf',
  [MimeType.TEXT_PLAIN]: 'txt',

  [MimeType.AUDIO_WAV]: 'wav',
  [MimeType.AUDIO_M4A]: 'm4a',
}

export const AllowedImageMimeTypes = [
  MimeType.IMAGE_JPEG,
  MimeType.IMAGE_PNG,
  MimeType.IMAGE_GIF,
  MimeType.IMAGE_WEBP,
]

export const MaxImageSize = 20 * 1024 * 1024
export const MaxDocumentSize = 20 * 1024 * 1024

export const UploadError = {
  NO_FILE: 'NO_FILE',
  INVALID_FILE_TYPE: 'INVALID_FILE_TYPE',
  INVALID_FILE_SIZE: 'INVALID_FILE_SIZE',
  FILE_EXISTS: 'FILE_EXISTS',
}
