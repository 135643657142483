import { getUsableHeight } from '@/client/utils/sizing'
import { useMergedRefs } from '@hooks/use-merged-refs'
import { useResizeObserver } from '@hooks/use-resize-observer'
import { useWindowResize } from '@hooks/use-window-resize'
import chunk from 'lodash/chunk'
import { useMemo, useRef, useState } from 'react'
import { FixedSizeGrid as Grid } from 'react-window'

export function MasonryGrid({ rowHeight = 240, items, render }) {
  /* @type {React.MutableRefObject<HTMLDivElement>} */
  const containerRef = useRef()
  const [height, setHeight] = useState(0)
  const [aproxColWidth, setAproxColWidth] = useState(320)

  const [{ width }, ref] = useResizeObserver()

  useWindowResize(() => {
    if (!containerRef.current) return
    setHeight(getUsableHeight(containerRef.current))

    // Make sure the card is responsive and visible on small devices.
    if (window.innerWidth > 768) {
      setAproxColWidth(400)
    } else {
      setAproxColWidth(width)
    }
  }, [containerRef.current, width])

  const columnCount = useMemo(() => {
    return width ? Math.floor(width / aproxColWidth) : 0
  }, [width, aproxColWidth])

  const columnScrollPadding = useMemo(() => {
    if (columnCount === 0) return 0
    return 24 / columnCount
  }, [columnCount])

  const columnWidth = useMemo(() => {
    if (columnCount === 0) return 0
    return width / columnCount - columnScrollPadding
  }, [columnCount, width, columnScrollPadding])

  const grid = useMemo(() => chunk(items, columnCount), [items, columnCount])

  const rowCount = useMemo(() => {
    if (columnCount === 0) return 0
    return Math.ceil(items.length / columnCount)
  }, [columnCount, items.length])

  const gap = 16

  const refs = useMergedRefs(ref, containerRef)

  return (
    <div ref={refs} className='w-full overflow-hidden'>
      {width && height ? (
        <Grid
          columnCount={columnCount}
          columnWidth={columnWidth}
          height={height}
          rowCount={rowCount}
          rowHeight={rowHeight}
          width={width}
        >
          {({ columnIndex, rowIndex, style }) => {
            const item = grid[rowIndex]?.[columnIndex]

            if (!item) {
              return null
            }

            return (
              <div
                style={{
                  ...style,

                  // Add a gap between columns and rows
                  top: rowIndex > 0 ? style.top + gap : style.top,
                  left: columnIndex > 0 ? style.left + gap : style.left,
                  height: rowIndex > 0 ? style.height - gap : style.height,
                  width: columnIndex > 0 ? style.width - gap : style.width,
                }}
              >
                {render(item, columnIndex, rowIndex)}
              </div>
            )
          }}
        </Grid>
      ) : null}
    </div>
  )
}
