import { ProgressBar } from '@components/ui/progress-bar'
import { IconInfinity } from '@tabler/icons-react'
import React from 'react'

export function SessionProgress({ game }) {
  const { sessionLength, sessionProgress } = game

  if (game.isUnlimitedMode) {
    return (
      <div className='mb-4 flex items-center gap-2'>
        <div className='ml-auto shrink-0'>
          {sessionProgress} / <IconInfinity className='h-4 w-4' />
        </div>
      </div>
    )
  }

  const percentage = sessionProgress / sessionLength

  return (
    <div className='mb-4 flex items-center gap-2'>
      <div className='flex h-2 grow flex-row justify-start rounded-full bg-gray-300'>
        <ProgressBar value={percentage} />
      </div>

      <div className='shrink-0'>
        {sessionProgress} / {sessionLength}
      </div>
    </div>
  )
}
