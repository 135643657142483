import { Tooltip } from '@mantine/core'
import { HelpCircleIcon } from 'lucide-react'
import React from 'react'

export function Help({ label }) {
  return (
    <Tooltip label={label} zIndex={100000} multiline>
      <HelpCircleIcon className='h-4 w-4' />
    </Tooltip>
  )
}
