import { Point } from '@/client/utils/point'
import {
  BoardEvent,
  DefaultContactField,
  NodeType,
} from '@/common/constants/boards'
import { BaseColor } from '@/common/constants/color'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { VirtualBounds } from '@components/boards/hooks/use-virtual-bounds'
import {
  fromVirtualX,
  fromVirtualY,
} from '@components/boards/utils/virtualization'
import { ContextMenu } from '@components/context-menu'
import { useClient } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t } from '@lingui/macro'
import { IconLayoutBoard } from '@tabler/icons-react'
import { ObjectId } from 'bson'
import {
  LucideContact,
  LucideFileQuestion,
  LucideImage,
  LucideLink,
  LucideMessageCircle,
  LucideMic,
  LucideText,
} from 'lucide-react'
import React, { useRef, useState } from 'react'
import { BoardLinkModal } from './board-link-modal'

type BoardContextMenuProps = {
  target: any
  viewportPosition: Point
  virtualBounds: VirtualBounds
  zoomMultiplier: number
}

export function InnerBoardContextMenu({
  target,
  viewportPosition,
  virtualBounds,
  zoomMultiplier,
}: BoardContextMenuProps) {
  const client = useClient()
  const rightClickPosition = useRef({ x: 0, y: 0 })

  const [isModalVisible, setModalVisible] = useState(false)

  function getPosition() {
    const x = fromVirtualX(
      rightClickPosition.current.x / zoomMultiplier - viewportPosition.x,
      virtualBounds,
    )
    const y = fromVirtualY(
      rightClickPosition.current.y / zoomMultiplier - viewportPosition.y,
      virtualBounds,
    )

    return { x, y }
  }

  const operations = useBoardOperations()

  const { authenticated, currentWorkspace } = useMetaboardAuth()

  function createBoard(nodeId: string, boardId: string) {
    return client.call('boards.create', {
      _id: boardId,
      name: t`New Board`,
      org: ObjectId.isValid(currentWorkspace) ? currentWorkspace : undefined,
      node: nodeId,
    })
  }

  if (!authenticated) return null

  return (
    <>
      <ContextMenu
        className='w-44'
        target={target}
        items={[
          {
            label: t`Text`,
            icon: <LucideText className='mr-1.5 h-4 w-4' />,
            onClick: () => {
              const { x, y } = getPosition()
              operations.addNode(x, y)
            },
          },
          {
            label: t`Image`,
            icon: <LucideImage className='mr-1.5 h-4 w-4' />,
            onClick: () => {
              const { x, y } = getPosition()
              operations.addNode(x, y, {
                type: NodeType.Image,
                width: 192,
                height: 64,
              })
            },
          },
          {
            label: t`Link`,
            icon: <LucideLink className='mr-1.5 h-4 w-4' />,
            onClick: async () => {
              const { x, y } = getPosition()
              const nodeId = await operations.addNode(x, y, {
                type: NodeType.URL,
                name: t`New Link`,
              })
              client.emit(BoardEvent.OpenNode, nodeId)
            },
          },
          {
            label: t`Board Embed`,
            icon: <IconLayoutBoard className='mr-1.5 h-4 w-4' />,
            onClick: async () => {
              const { x, y } = getPosition()

              const nodeId = await operations.addNode(
                x,
                y,
                {
                  type: NodeType.Board,
                  name: t`New Board`,
                  width: 192,
                  color: BaseColor.Fuchsia,
                },
                true,
              )

              const boardId = new ObjectId().toString()

              await createBoard(nodeId, boardId)

              await operations.updateNode(nodeId, {
                boardTarget: boardId,
              })
            },
            premium: true,
          },
          {
            label: t`Board Link`,
            icon: <IconLayoutBoard className='mr-1.5 h-4 w-4' />,
            onClick: () => setModalVisible(true),
          },
          {
            label: t`Contact`,
            icon: <LucideContact className='mr-1.5 h-4 w-4' />,
            onClick: async () => {
              const { x, y } = getPosition()
              const nodeId = await operations.addNode(x, y, {
                type: NodeType.Contact,
                name: t`New Contact`,
                width: 192,
                contactInfo: [
                  {
                    field: DefaultContactField.Phone,
                    value: '',
                  },
                ],
              })

              client.emit(BoardEvent.OpenNode, nodeId)
            },
          },
          {
            label: t`AI Chat`,
            icon: <LucideMessageCircle className='mr-1.5 h-4 w-4' />,
            onClick: async () => {
              const { x, y } = getPosition()
              const nodeId = await operations.addNode(x, y, {
                type: NodeType.AIChat,
                name: t`New AI Chat`,
                width: 192,
              })
              client.emit(BoardEvent.OpenNode, nodeId)
            },
            premium: true,
          },
          {
            label: t`Voice`,
            icon: <LucideMic className='mr-1.5 h-4 w-4' />,
            onClick: () => {
              const { x, y } = getPosition()
              operations.addNode(x, y, {
                name: t`New Voice Recording`,
                type: NodeType.Voice,
                width: 192,
              })
            },
            premium: true,
          },
          {
            label: t`Flashcards`,
            icon: <LucideFileQuestion className='mr-1.5 h-4 w-4' />,
            onClick: async () => {
              const { x, y } = getPosition()
              const nodeId = await operations.addNode(x, y, {
                name: t`New Flashcards`,
                type: NodeType.Flashcards,
                width: 192,
                color: BaseColor.Green,
              })
              client.emit(BoardEvent.OpenNode, nodeId)
            },
            premium: true,
            admin: true,
          },
        ]}
        onPositionChange={position => {
          rightClickPosition.current = position
        }}
        strict
      />
      <BoardLinkModal
        visible={isModalVisible}
        close={() => setModalVisible(false)}
        operations={operations}
        getPosition={getPosition}
      />
    </>
  )
}
