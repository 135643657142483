import { cn } from '@/client/utils/cn'
import { extractTextFromHTML } from '@/client/utils/dom-utils'
import {
  ensureLimits,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
} from '@/common/boards/constraints'
import { BoardEvent, NodeType } from '@/common/constants/boards'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { useGroupEvents } from '@components/boards/hooks/use-group-events'
import { useInteractionForNode } from '@components/boards/hooks/use-interaction-for-node'
import { useNodeLongPress } from '@components/boards/hooks/use-node-long-press'
import { useNodeResize } from '@components/boards/hooks/use-node-resize'
import { VirtualBounds } from '@components/boards/hooks/use-virtual-bounds'
import { NodeChecklistBadge } from '@components/boards/node-checklist-badge'
import { NodeContent } from '@components/boards/node-content'
import { NodeContextMenu } from '@components/boards/node-context-menu'
import { getNodeClasses } from '@components/boards/utils/nodes'
import { SimplePoint } from '@components/boards/utils/virtualization'
import { useClient } from '@helenejs/react'
import { useHeleneEvent } from '@hooks/use-helene-event'
import { mergeRefs, useResizeObserver } from '@mantine/hooks'
import { LucideFile, LucideFileText } from 'lucide-react'
import React, { useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { EdgeHandles } from './edge-handles'

type DraggableNodeProps = {
  id: string
  node: any
  style?: React.CSSProperties
  className?: string
  isSelected?: boolean
  virtualBounds: VirtualBounds
  viewportPositionRef: React.MutableRefObject<SimplePoint>
}

export function DraggableNode({
  id,
  node,
  style,
  className,
  isSelected,
  virtualBounds,
  viewportPositionRef,
}: DraggableNodeProps) {
  const client = useClient()

  const startPos = useRef({ x: node.x, y: node.y })

  const [nodeElem, setNodeElem] = useState(null)
  const [position, setPosition] = useState({ x: node.x, y: node.y })
  const [dragging, setDragging] = useState(false)
  const [resizing, setResizing] = useState(false)

  useEffect(() => {
    // Prevent resetting the position when dragging soon after adding a node
    if (dragging) return
    setPosition({ x: node.x, y: node.y })
  }, [node.x, node.y])

  const initialSize = { width: node.width, height: node.height }

  const [size, setSize] = useState({
    width: ensureLimits(initialSize.width ?? 0, minWidth, maxWidth),
    height: ensureLimits(initialSize.height ?? 0, minHeight, maxHeight),
  })

  useEffect(() => {
    if (dragging) return
    setSize({
      width: node.width,
      height: node.height,
    })
  }, [node.width, node.height])

  const [editing, setEditing] = useState(false)

  const startMousePositionRef = useRef(null)

  const { isReadOnly, isWritable, zoomMultiplier } = useBoardState()

  const operations = useBoardOperations()

  const onResizeStart = useNodeResize({
    id,
    node,
    position,
    size,
    setSize,
    zoomMultiplier,
    setDragging,
    setResizing,
    initialSize,
    startMousePositionRef,
    dragging,
    resizing,
    startPos,
  })

  const mayMoveNode = useRef(false)

  const bind = useNodeLongPress({ mayMoveNode, setDragging })

  useInteractionForNode({
    id,
    nodeElem,
    zoomMultiplier,
    viewportPositionRef,
    mayMoveNode,
    setDragging,
    node,
  })

  useGroupEvents({
    id,
    position,
    size,
    operations,
    setPosition,
    startPos,
  })

  useHeleneEvent(BoardEvent.StartRenaming, ({ id }) => {
    if (node._id === id) {
      setEditing(true)
    }
  })

  useHotkeys('esc', () => {
    setDragging(false)
    setResizing(false)
  })

  const [ref, rect] = useResizeObserver()

  const hasContent =
    node.content && extractTextFromHTML(node.content).length > 0

  return (
    <div
      ref={mergeRefs(ref, setNodeElem)}
      style={{
        top: `${position.y - virtualBounds.minY}px`,
        left: `${position.x - virtualBounds.minX}px`,
        width: `${size.width}px`,
        height: `${size.height}px`,
        ...style,
      }}
      className={getNodeClasses(
        {
          dragging,
          isSelected,
          node,
        },
        className,
      )}
      data-node-id={id}
      data-node
      {...bind()}
    >
      {!isReadOnly ? (
        <NodeContextMenu
          target={nodeElem}
          node={node}
          onEdit={() => setEditing(true)}
        />
      ) : null}

      <NodeContent
        node={node}
        editing={editing}
        id={id}
        setSize={setSize}
        setEditing={setEditing}
        rect={rect}
        isDragging={dragging}
        isResizing={resizing}
      />

      {/* Hide resize handle if image has no file yet */}
      {isWritable &&
      !(node.type === NodeType.Image && !node._temporaryUrl && !node.image) ? (
        <div
          className='absolute bottom-0 right-0 flex h-6 w-6 cursor-nwse-resize items-center justify-center'
          onMouseDownCapture={onResizeStart}
          onTouchStartCapture={onResizeStart}
          onClickCapture={e => e.stopPropagation()}
          data-resize-handle
        >
          <div className='h-[4px] w-[4px] rounded-full bg-gray-300' />
        </div>
      ) : null}

      <EdgeHandles id={id} />

      {![NodeType.Flashcards, NodeType.AIChat].includes(node.type) ? (
        <button
          className={cn(
            'absolute right-0 top-0 rounded-bl rounded-tr p-1.5 text-white transition-all duration-200 group-hover:block',
            'bg-black/15 hover:bg-black/30',
            {
              'lg:hidden': !hasContent,
            },
          )}
          onClickCapture={e => {
            e.stopPropagation()
            client.emit(BoardEvent.OpenNode, id)
          }}
          onMouseDownCapture={e => e.stopPropagation()}
          onTouchStartCapture={e => e.stopPropagation()}
        >
          {hasContent ? <LucideFileText size={10} /> : <LucideFile size={10} />}
        </button>
      ) : null}

      <NodeChecklistBadge content={node.content} />
    </div>
  )
}
