import { IconArrowRight } from '@tabler/icons-react'
import { motion } from 'framer-motion'
import React from 'react'

const ease = [0.16, 1, 0.3, 1]

export function HeroPill() {
  return (
    <motion.a
      href='https://metaboard.cc/article/boosting-visual-and-spatial-learning-with-metaboard'
      className='bg-primary/20 ring-accent inline-flex w-auto items-center space-x-2 whitespace-pre rounded-full px-2 py-1 ring-1'
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease }}
      target='_blank'
    >
      <div className='bg-accent text-primary w-fit rounded-full px-2 py-0.5 text-center text-xs font-medium sm:text-sm'>
        📣 Announcement
      </div>
      <p className='text-primary m-0 p-0 text-xs font-medium sm:text-sm'>
        Boosting Visual and Spatial Learning with Metaboard
      </p>
      <IconArrowRight className='ml-1' />
    </motion.a>
  )
}
