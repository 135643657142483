import React from 'react'

export function Recommendation({ name, url, image, text }) {
  return (
    <div className='mx-auto max-w-xl space-y-2'>
      <div className='flex items-center gap-2'>
        <img
          src={image}
          alt='Isaque Chaves'
          className='my-0 h-8 w-8 rounded-full'
        />
        <a
          className='text-sm hover:underline'
          href={url}
          target='_blank'
          rel='noreferrer'
        >
          {name}
        </a>
      </div>
      <div className='text-sm font-medium'>“{text}”</div>
    </div>
  )
}
