import React from 'react'
import { useField } from 'formik'
import { FieldOutline } from './field-outline'
import { SwitchInput } from '@components/ui/input/switch-input'

export function SwitchField({
  outlineProps,
  name,
  className,
  errorClassName,
  label,
}) {
  const [field, meta, helper] = useField(name)

  return (
    <FieldOutline meta={meta} errorClassName={errorClassName} {...outlineProps}>
      <div className='flex items-center gap-2'>
        <SwitchInput
          checked={field.value}
          onCheckedChange={helper.setValue}
          className={className}
        />

        <label className='text-sm'>{label}</label>
      </div>
    </FieldOutline>
  )
}
