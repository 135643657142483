import { useOrgs } from '@/client/hooks/use-orgs'
import { BoardFilter, BoardWorkspace } from '@/common/constants/boards'
import { BoardGrid } from '@components/boards/grid/board-grid'
import { useBoardSearch } from '@components/boards/hooks/use-board-search'
import { TimedLoader } from '@components/timed-loader'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t } from '@lingui/macro'
import { Input, SegmentedControl } from '@mantine/core'
import { IconSearch } from '@tabler/icons-react'
import { useLocalStorageState } from 'ahooks'
import React, { useMemo } from 'react'

type BoardBrowserProps = {
  inputRef?: React.RefObject<HTMLInputElement>
}

export function BoardBrowser({ inputRef }: BoardBrowserProps) {
  const { userId, currentWorkspace, setCurrentWorkspace } = useMetaboardAuth()
  const { loading, boards, setSearch } = useBoardSearch()

  const orgs = useOrgs()

  const [filter, setFilter] = useLocalStorageState<string>(
    'board-browser-filter',
    {
      defaultValue: BoardFilter.All,
    },
  )

  const _boards = useMemo(() => {
    if (filter === BoardFilter.All) return boards

    return boards.filter(board => {
      switch (filter) {
        case BoardFilter.Public:
          return board.author === userId && board.privacy === BoardFilter.Public
        case BoardFilter.Private:
          return (
            board.author === userId && board.privacy === BoardFilter.Private
          )
        case BoardFilter.Shared:
          return board.author === userId && board.accessList?.length > 0
        case BoardFilter.External:
          return board.author !== userId
      }
    })
  }, [filter, boards])

  if (loading && !boards.length) return <TimedLoader />

  return (
    <div className='@container'>
      <div className='mb-2 flex flex-row flex-wrap gap-2'>
        <SegmentedControl
          size='xs'
          color='indigo'
          value={filter}
          onChange={setFilter}
          data={[
            {
              label: t`All`,
              value: BoardFilter.All,
            },
            {
              label: t`Private`,
              value: BoardFilter.Private,
            },
            {
              label: t`Public`,
              value: BoardFilter.Public,
            },
            {
              label: t`Shared`,
              value: BoardFilter.Shared,
            },
            {
              label: t`External`,
              value: BoardFilter.External,
            },
          ]}
        />

        {orgs ? (
          <SegmentedControl
            size='xs'
            color='orange'
            value={currentWorkspace}
            onChange={setCurrentWorkspace}
            data={[
              {
                label: t`All`,
                value: BoardWorkspace.All,
              },
              ...orgs,
            ]}
          />
        ) : null}
      </div>

      <Input
        leftSection={<IconSearch size={16} />}
        onChange={e => {
          setSearch(e.currentTarget.value)
        }}
        placeholder={t`Search boards...`}
        className='mb-4 flex-grow'
        ref={inputRef}
      />

      <BoardGrid boards={_boards} />
    </div>
  )
}
