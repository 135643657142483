import { LanguageCode } from '@/common/constants'
import { messages as enMessages } from '@/common/locales/en'
import { messages as ptMessages } from '@/common/locales/pt'
import { i18n } from '@lingui/core'

export function getBrowserLanguage() {
  // @ts-ignore
  const browserLanguage = navigator.language || navigator.userLanguage

  if (browserLanguage.startsWith('pt')) {
    return LanguageCode.PT
  }

  return LanguageCode.EN
}

export async function initI18n() {
  i18n.load({
    [LanguageCode.EN]: enMessages,
    [LanguageCode.PT]: ptMessages,
  })

  const defaultLanguage = getBrowserLanguage()

  i18n.activate(defaultLanguage)

  document.documentElement.lang = defaultLanguage
}
