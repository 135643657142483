import { ClientBoardNodeCollection } from '@/client/data'
import { cn } from '@/client/utils/cn'
import { isTouchDevice } from '@/client/utils/environment'
import {
  AIChatModel,
  AIChatRole,
  AIModel,
  SupportedAIModels,
} from '@/common/constants/ai'
import { BoardEvent, BoardNode } from '@/common/constants/boards'
import { useBoardOperations } from '@components/boards/hooks/use-board-operations'
import { useBoardState } from '@components/boards/hooks/use-board-state'
import { getNodeDimensions } from '@components/boards/utils/nodes'
import { unescapeHtml } from '@components/boards/utils/unescape-html'
import { Markdown } from '@components/markdown'
import { Button } from '@components/ui/button'
import { Combobox } from '@components/ui/combobox'
import { Tag } from '@components/ui/tag'
import { useClient } from '@helenejs/react'
import { useHeleneEvent } from '@hooks/use-helene-event'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t, Trans } from '@lingui/macro'
import { useLocalStorageState } from 'ahooks'
import defer from 'lodash/defer'
import React, { useCallback, useState } from 'react'

type NodeChatProps = {
  node: BoardNode<string>
}

const modelOptions = SupportedAIModels.map(key => ({
  label: AIChatModel[key].description,
  value: key,
}))

export function NodeAIChat({ node }: NodeChatProps) {
  const { isWritable } = useBoardState()
  const [prompt, setPrompt] = useState('')
  const [model, setModel] = useLocalStorageState('metaboard:ai-chat-model:v1', {
    defaultValue: node.chat?.model ?? AIModel.Claude35Sonnet,
  })
  const [streamedResponse, setStreamedResponse] = useState('')
  const [generating, setGenerating] = useState(false)

  useHeleneEvent(
    BoardEvent.MessageToken,
    token => setStreamedResponse(sr => sr + token),
    [setStreamedResponse],
  )

  const currentModel = node.chat?.model ?? model

  const modelData = AIChatModel[currentModel]

  const sendMessage = useSendMessage({
    node,
    model: modelData?.name ?? t`Unknown`,
    prompt,
    setPrompt,
    setStreamedResponse,
    setGenerating,
  })

  const { user } = useMetaboardAuth()

  const disabled =
    node.chat?.messages.length >= 100 ||
    !SupportedAIModels.includes(currentModel)

  return (
    <div className='flex h-full max-h-full flex-col gap-4 overflow-hidden p-4'>
      <div>
        {!node.chat?.model ? (
          <Combobox
            items={modelOptions}
            value={modelData?.name ?? t`Unknown`}
            onChange={m => setModel(m as AIModel)}
          />
        ) : (
          <Tag>{modelData.description}</Tag>
        )}
      </div>

      <div className='flex flex-col-reverse gap-4 overflow-y-auto pr-4'>
        {streamedResponse ? (
          <div className='rounded p-4 odd:bg-slate-50 dark:odd:bg-slate-600'>
            <div className='text-sm'>
              <div className='mb-2 font-semibold'>
                <Trans>Assistant</Trans>
              </div>
              <Markdown>{streamedResponse}</Markdown>
            </div>
          </div>
        ) : null}
        {node.chat?.messages
          .map((message, i) => {
            return (
              <div
                key={i}
                className='rounded p-4 odd:bg-slate-50 dark:odd:bg-slate-600'
              >
                {message.content.map((content, j) => {
                  if (content.type === 'text') {
                    return (
                      <div key={j} className='text-sm'>
                        <div className='mb-2 font-semibold'>
                          {message.role === AIChatRole.User ? (
                            user?.name ?? <Trans>User</Trans>
                          ) : (
                            <Trans>Assistant</Trans>
                          )}
                        </div>
                        <Markdown>{unescapeHtml(content.text)}</Markdown>
                      </div>
                    )
                  }
                  return null
                })}
              </div>
            )
          })
          .reverse()}
      </div>

      {isWritable ? (
        <div className='flex items-center gap-4 overflow-visible'>
          <textarea
            className={cn(
              'flex-1',
              'scrollbar-hidden max-h-[5rem] max-w-full resize-none content-center rounded',
              'border-opacity-0 bg-slate-50 px-5 py-4 pr-[3.125rem] text-sm text-black text-opacity-70',
              'transition duration-200 ease-out placeholder:text-black placeholder:opacity-40',
              'dark:border-slate-700 dark:border-opacity-70 dark:bg-slate-600 dark:bg-opacity-70 dark:text-white ',
              '!focus:outline-none !outline-none dark:placeholder:text-white dark:placeholder:opacity-70',
            )}
            placeholder={t`Enter instructions or prompt here...`}
            aria-label={t`Enter instructions or prompt here...`}
            value={prompt}
            onChange={e => setPrompt(e.target.value)}
            rows={1}
            cols={30}
            disabled={generating || disabled}
            onKeyDown={e => {
              if (e.key === 'Enter' && !isTouchDevice() && !e.shiftKey) {
                sendMessage()
              }
            }}
          />
          <Button
            onClick={sendMessage}
            loading={generating}
            className='shrink-0'
            disabled={disabled}
          >
            <span>Send</span>
          </Button>
        </div>
      ) : null}
    </div>
  )
}

function useSendMessage({
  node,
  model,
  prompt,
  setPrompt,
  setStreamedResponse,
  setGenerating,
}) {
  const client = useClient()
  const operations = useBoardOperations()

  return useCallback(async () => {
    if (!prompt) return

    setStreamedResponse('')
    setPrompt('')
    setGenerating(true)

    if (!node.chat) {
      await ClientBoardNodeCollection.update(
        { _id: node._id },
        {
          $set: {
            chat: {
              model,
              messages: [],
            },
          },
        },
      )

      defer(() => {
        const dimensions = getNodeDimensions(node, prompt)

        operations.updateNode(node._id, {
          name: prompt,
          ...dimensions,
        })
      })
    }

    await ClientBoardNodeCollection.update(
      { _id: node._id },
      {
        $push: {
          'chat.messages': {
            role: AIChatRole.User,
            content: [
              {
                type: 'text',
                text: prompt,
              },
            ],
          },
        },
      },
    )

    const result = await client.call(
      'boards.sendChatMessage',
      {
        boardId: node.board,
        nodeId: node._id,
        model,
        prompt,
      },
      {
        timeout: 300000,
      },
    )

    ClientBoardNodeCollection.update(
      { _id: node._id },
      {
        $set: {
          chat: result,
        },
      },
    )

    setGenerating(false)
    setStreamedResponse('')
  }, [prompt, model, node._id, node.board])
}
