import HoleIcon from '@/client/assets/hole.png'
import { EnrolledDeckCard } from '@/client/components/enrolled-deck-card'
import { Button } from '@/client/components/ui/button'
import { MetaboardEvent } from '@/common/events'
import { HorizontalLoader } from '@components/horizontal-loader'
import { Collection } from '@helenejs/data'
import { IDBStorage } from '@helenejs/data/lib/browser/idb-storage'
import { useLocalEvent, useRemoteEvent } from '@helenejs/react'
import { useData } from '@hooks/use-data'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

const collection = new Collection({
  name: 'enrolled:decks',
  storage: new IDBStorage(),
  autoload: true,
})

export function EnrolledDecks() {
  const { userId } = useMetaboardAuth()

  const { loading, data, refresh } = useData({
    method: 'deck.listEnrolled',
    channel: userId,
    sort: {
      'deckHistory.updatedAt': -1,
    },
    collectionName: `enrolled:decks:${userId}`,
    collection,
  })

  useRemoteEvent(
    {
      event: MetaboardEvent.USER_CHANGED,
      channel: userId,
    },
    refresh,
    [refresh],
  )

  useLocalEvent(
    {
      event: MetaboardEvent.UNENROLL_DECK,
    },
    async deckId => {
      await collection.remove({ _id: deckId })
    },
    [collection],
  )

  if (loading) return <HorizontalLoader />

  if (isEmpty(data)) {
    return (
      <div className='mx-auto mb-16 flex max-w-3xl flex-col items-center justify-center gap-4'>
        <img src={HoleIcon} alt='Hole Emoji' width={128} />

        <div className='-mt-8 text-sm text-gray-500 dark:text-slate-400 '>
          <Trans>You don't have any flashcard decks yet</Trans>
        </div>

        <Button to='/decks'>
          <Trans>Decks</Trans>
        </Button>
      </div>
    )
  }

  return (
    <div className='grid grid-cols-1 gap-4 overflow-x-hidden p-4 md:grid-cols-2 xl:grid-cols-3'>
      {data.map(
        ({
          _id,
          title,
          slug,
          image,
          reviewCount,
          exerciseCount,
          deckHistory: { exerciseCount: learnedCount = 0 } = {},
          nextReviewAvailable: [review],
        }) => {
          return (
            <EnrolledDeckCard
              key={_id}
              _id={_id}
              title={title}
              slug={slug}
              image={image}
              reviewCount={reviewCount}
              exerciseCount={exerciseCount}
              learnedCount={learnedCount}
              review={review}
            />
          )
        },
      )}
    </div>
  )
}
