import { cn } from '@/client/utils/cn'
import { BoardNode, FontSize, NodeType } from '@/common/constants/boards'
import { BaseColor } from '@/common/constants/color'
import { ColorButton } from '@components/boards/color-button'
import {
  BoardOperations,
  useBoardOperations,
} from '@components/boards/hooks/use-board-operations'
import { getNodeDimensions } from '@components/boards/utils/nodes'
import { ContextMenu, ContextMenuSeparator } from '@components/context-menu'
import { useClient } from '@helenejs/react'
import { t, Trans } from '@lingui/macro'
import {
  IconBrandYoutube,
  IconBrandYoutubeFilled,
  IconPin,
  IconPinnedOff,
} from '@tabler/icons-react'
import defer from 'lodash/defer'
import {
  LucideALargeSmall,
  LucideBan,
  LucideCopy,
  LucideDelete,
  LucideEdit,
  LucideExternalLink,
} from 'lucide-react'
import React from 'react'

type NodeContextMenuProps = {
  target: React.MutableRefObject<HTMLDivElement>
  children?: React.ReactNode
  node: BoardNode<string>
  onEdit?: () => void
}

export function NodeContextMenu({
  target,
  node,
  onEdit,
}: NodeContextMenuProps) {
  const operations = useBoardOperations()

  const client = useClient()

  if (!operations.initialized) return null

  return (
    <ContextMenu
      target={target}
      items={[
        node.type !== NodeType.Image
          ? {
              label: t`Rename`,
              icon: <LucideEdit className='mr-1.5 h-4 w-4' />,
              onClick: () => defer(onEdit),
            }
          : null,
        {
          label: t`Delete`,
          icon: <LucideDelete className='mr-1.5 h-4 w-4' />,
          onClick: () => operations.deleteNode(node._id),
          className: 'text-red-500 dark:text-red-400',
        },
        node.isYouTubeVideo
          ? {
              label: node.isYouTubeEmbed ? t`Hide Video` : t`Show Video`,
              icon: node.isYouTubeEmbed ? (
                <IconBrandYoutube className='mr-1.5 h-4 w-4' />
              ) : (
                <IconBrandYoutubeFilled className='mr-1.5 h-4 w-4' />
              ),
              onClick: () =>
                operations.updateNode(node._id, {
                  isYouTubeEmbed: !node.isYouTubeEmbed,
                  ...(!node.isYouTubeEmbed
                    ? { width: 640, height: 352 }
                    : getNodeDimensions(node, node.name)),
                }),
            }
          : null,
        node.color
          ? {
              label: t`Remove Color`,
              icon: <LucideBan className='mr-1.5 h-4 w-4' />,
              onClick: () => {
                operations.updateNode(node._id, {
                  color: null,
                })
              },
            }
          : null,
        {
          label: t`Duplicate`,
          icon: <LucideCopy className='mr-1.5 h-4 w-4' />,
          onClick: () => {
            operations.duplicateNode(node._id)
          },
        },
        {
          label: t`Copy Link`,
          icon: <LucideExternalLink className='mr-1.5 h-4 w-4' />,
          onClick: () => {
            navigator.clipboard
              .writeText(
                `${window.location.origin}/b/${node.board}/n/${node._id}`,
              )
              .catch(console.error)
          },
        },
        {
          label: node.isMapPin ? t`Unpin` : t`Pin`,
          icon: node.isMapPin ? (
            <IconPinnedOff className='mr-1.5 h-4 w-4' />
          ) : (
            <IconPin className='mr-1.5 h-4 w-4' />
          ),
          onClick: () => {
            operations.updateNode(node._id, {
              isMapPin: !node.isMapPin,
            })
          },
        },
      ]}
    >
      <ContextMenuSeparator />
      <div className='grid h-8 w-full grid-cols-3'>
        <FontSizeButton
          className={
            !node.fontSize || node.fontSize === FontSize.Small
              ? 'bg-slate-200 dark:bg-slate-600'
              : ''
          }
          onClick={() => {
            changeSize(node, operations, FontSize.Small)
          }}
        >
          <LucideALargeSmall size={12} />
        </FontSizeButton>
        <FontSizeButton
          className={
            node.fontSize === FontSize.Medium
              ? 'bg-slate-200 dark:bg-slate-600'
              : ''
          }
          onClick={() => {
            changeSize(node, operations, FontSize.Medium)
          }}
        >
          <LucideALargeSmall size={16} />
        </FontSizeButton>
        <FontSizeButton
          className={
            node.fontSize === FontSize.Large
              ? 'bg-slate-200 dark:bg-slate-600'
              : ''
          }
          onClick={() => {
            changeSize(node, operations, FontSize.Large)
          }}
        >
          <LucideALargeSmall size={20} />
        </FontSizeButton>
      </div>
      <ContextMenuSeparator />
      <div className='p-2 text-center text-sm font-semibold'>
        <Trans>Color</Trans>
      </div>
      <div className='grid w-full grid-cols-6 flex-wrap gap-2 p-2 pt-0'>
        {Object.values(BaseColor).map(color => (
          <ColorButton
            key={color}
            color={color}
            currentColor={node.color}
            onClick={() => {
              operations.updateNode(node._id, { color })
            }}
          />
        ))}
      </div>
    </ContextMenu>
  )
}

function FontSizeButton({ children, className, ...rest }) {
  return (
    <button
      className={cn(
        'flex items-center justify-center hover:bg-slate-200 dark:hover:bg-slate-600',
        className,
      )}
      {...rest}
    >
      {children}
    </button>
  )
}

function changeSize(
  node: BoardNode<string>,
  operations: BoardOperations,
  fontSize: FontSize,
) {
  const dim = getNodeDimensions({ ...node, fontSize }, node.name)
  console.log({ dim })
  operations.updateNode(node._id, {
    fontSize,
    ...dim,
  })
}
