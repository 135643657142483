import { MetaboardEvent } from '@/common/events'
import { useClient } from '@helenejs/react'
import { Trans } from '@lingui/macro'
import { Button } from '@mantine/core'
import { LucideLogIn } from 'lucide-react'
import React from 'react'

export function LandingSignUp() {
  const client = useClient()

  return (
    <Button
      onClick={() => client.emit(MetaboardEvent.SIGN_IN)}
      leftSection={<LucideLogIn size={16} />}
      variant='gradient'
      gradient={{
        from: 'teal',
        to: 'cyan',
      }}
    >
      <Trans>Start using Metaboard for free</Trans>
    </Button>
  )
}
