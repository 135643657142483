import { useCallback } from 'react'
import { StripePromise } from '@/client/utils/stripe'
import { useClient } from '@helenejs/react'

export function useCheckout(successPath = '/', cancelPath = '/') {
  const client = useClient()

  return useCallback(
    async (priceId: string) => {
      const stripe = await StripePromise

      const { sessionId } = await client.call('billing.checkout', {
        priceId,
        successPath,
        cancelPath,
      })

      await stripe.redirectToCheckout({
        sessionId,
      })
    },
    [client],
  )
}
