import { NodeType } from '@/common/constants/boards'
import { BaseColor } from '@/common/constants/color'
import { BoardGrid } from '@components/boards/grid/board-grid'
import { useBoard } from '@components/boards/hooks/use-board'
import { useBoardSearch } from '@components/boards/hooks/use-board-search'
import { TimedLoader } from '@components/timed-loader'
import { Modal } from '@components/ui/modal'
import { t } from '@lingui/macro'
import { Input } from '@mantine/core'
import { IconSearch } from '@tabler/icons-react'
import React from 'react'

export function BoardLinkModal({ visible, close, operations, getPosition }) {
  const board = useBoard()

  const { loading, boards, setSearch } = useBoardSearch({
    org: board?.org ? board?.org : { $exists: false },
  })

  const handleLinkBoard = async board => {
    const { x, y } = getPosition()

    const nodeId = await operations.addNode(
      x,
      y,
      {
        type: NodeType.Board,
        name: board.name,
        isLinkedBoard: true,
        width: 192,
        color: BaseColor.Pink,
      },
      true,
    )

    await operations.updateNode(nodeId, { boardTarget: board._id })
  }

  if (loading && !boards.length) return <TimedLoader />

  return (
    <Modal
      title={t`Board Link`}
      isOpen={visible}
      onClose={close}
      className='prose text-center @container dark:prose-invert'
    >
      <Input
        leftSection={<IconSearch size={16} />}
        onChange={e => {
          setSearch(e.currentTarget.value)
        }}
        placeholder={t`Search boards...`}
        className='mb-4 flex-grow'
      />

      <BoardGrid
        boards={boards}
        onClick={board => {
          handleLinkBoard(board).catch(console.error)
          close()
        }}
      />
    </Modal>
  )
}
