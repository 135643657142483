import { cn } from '@/client/utils/cn'
import { BoardNavbar } from '@components/boards/board-navbar'
import { useGlobalChangeEvents } from '@components/boards/hooks/use-global-change-events'
import { Footer } from '@components/footer'
import { GlobalAlert } from '@components/global-alert'
import { LiveNotifications } from '@components/live-notifications'
import { OnboardingSlides } from '@components/navigation/onboarding-slides'
import { SignInDialog } from '@components/sign-in-dialog'
import { useClient } from '@helenejs/react'
import { useInteractionTime } from '@hooks/use-interaction-time'
import { useTheme } from '@hooks/use-theme'
import { createTheme, MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import React from 'react'

type LayoutProps = {
  children: React.ReactNode
  noSpacing?: boolean
}

const theme = createTheme({
  components: {
    Modal: {
      defaultProps: {
        zIndex: 999999,
      },
    },
    Popover: {
      defaultProps: {
        zIndex: 999999,
      },
    },
  },
})

function Layout({ children }: LayoutProps) {
  const { isDark } = useTheme()

  return (
    <MantineProvider theme={theme} forceColorScheme={isDark ? 'dark' : 'light'}>
      <Notifications style={{ zIndex: 999999 }} />
      <div
        className={cn(
          'flex min-h-screen flex-col overflow-hidden px-4 lg:overflow-visible',
        )}
      >
        <BoardNavbar />

        <section className={cn('mx-auto mb-12 mt-20 w-full max-w-3xl')}>
          {children}
        </section>

        <Footer />

        <SignInDialog />

        <GlobalAlert />

        <LiveNotifications />

        <div className='fixed bottom-4 left-4 flex items-center gap-2'>
          <OnboardingSlides />
        </div>
      </div>
    </MantineProvider>
  )
}

export function CleanLayout({ children }) {
  const client = useClient()

  useInteractionTime(time => {
    client.void('analytics.interaction', { time })
  })

  useGlobalChangeEvents()

  return <Layout>{children}</Layout>
}
