import { BaseDocument, Collection, CollectionEvent } from '@helenejs/data'
import { IDBStorage } from '@helenejs/data/lib/browser/idb-storage'
import {
  useClient,
  useFind,
  useLocalEvent,
  useRemoteEvent,
} from '@helenejs/react'
import { ClientEvents, HeleneEvents } from '@helenejs/utils'
import { useDeepCompareEffect, useThrottleFn } from 'ahooks'
import useCreation from 'ahooks/lib/useCreation'
import deepDiff from 'deep-diff'
import chunk from 'lodash/chunk'
import isEmpty from 'lodash/isEmpty'
import set from 'lodash/set'
import { useState } from 'react'

const storage = new IDBStorage()

type Props = {
  method: string
  channel?: string
  params?: any

  /**
   * Scope the data to a specific filter.
   */
  filter?: Record<string, any>
  sort?: Record<string, 1 | -1>
  projection?: Record<string, 0 | 1>
  selectiveSync?: boolean
  authenticated?: boolean
  collectionName?: string
  collection?: Collection
  single?: boolean
  required?: any[]
}

async function waitForCollectionReady(collection: Collection) {
  if (!collection.ready) {
    await collection.waitFor(CollectionEvent.READY)
  }
}

async function handleSelectiveSync(
  collection: Collection,
  client: any,
  method: string,
  filter: any,
  params: any,
) {
  const { updatedAt: lastUpdatedAt } = await collection.findOne(
    filter,
    { updatedAt: 1 },
    { updatedAt: -1 },
  )

  const documentIds = (
    await collection.find(filter).projection({ _id: 1 })
  ).map(({ _id }) => _id)

  const { data, documentIdsToRemove } = await client.call(method, {
    ...params,
    documentIds,
    lastUpdatedAt,
  })

  if (!isEmpty(documentIdsToRemove)) {
    await collection.deleteMany({
      $and: [
        filter,
        {
          _id: { $in: documentIdsToRemove },
        },
      ],
    })
  }

  return data
}

async function updateDocument(
  collection: Collection,
  datum: BaseDocument,
  filter: any,
) {
  const existing = await collection.findOne({ _id: datum._id })

  if (existing) {
    const removedFields = Object.keys(existing).filter(key => !(key in datum))

    const diff = deepDiff(existing, datum)
    if (!diff) return

    await collection.updateOne(
      { ...filter, _id: datum._id },
      {
        $set: datum,
        $unset: removedFields.reduce((acc, key) => {
          acc[key] = ''
          return acc
        }, {}),
      },
    )
  } else {
    try {
      await collection.insert(datum)
    } catch (error) {
      console.error(error)
      console.log(datum)
    }
  }
}

async function cleanupUnusedDocuments(
  collection: Collection,
  filter: any,
  retrievedIds: string[],
  selectiveSync: boolean,
) {
  if (!selectiveSync) {
    const toRemove = await collection.find({
      $and: [
        filter,
        {
          _id: { $nin: retrievedIds },
        },
      ],
    })

    if (!isEmpty(toRemove)) {
      await collection.deleteMany({
        $and: [
          filter,
          {
            _id: { $in: toRemove.map(({ _id }) => _id) },
          },
        ],
      })
    }
  }
}

export function useData({
  method,
  channel,
  params,
  filter = {},
  sort,
  projection,
  selectiveSync = false,
  authenticated = false,
  collectionName = null,
  collection = null,
  single = false,
  required = [],
}: Props) {
  const name = collection?.name ?? collectionName ?? `collection:${method}`

  const innerCollection = useCreation(
    () =>
      collection ??
      new Collection({
        name,
        storage,
        timestamps: true,
        autoload: true,
      }),
    [name, collection],
  )

  const [loading, setLoading] = useState(false)

  const client = useClient()

  const refresh = useThrottleFn(
    async () => {
      if (loading || !innerCollection) return

      await waitForCollectionReady(innerCollection)

      if (authenticated && !client.authenticated) {
        await innerCollection.deleteMany(filter)
        return
      }

      if (required.some(item => item === undefined || item === null)) {
        return
      }

      const data = await innerCollection.find(filter, projection).sort(sort)
      if (!data.length) {
        setLoading(true)
      }

      try {
        let response: BaseDocument | BaseDocument[]

        if ((await innerCollection.count(filter)) && selectiveSync) {
          response = await handleSelectiveSync(
            innerCollection,
            client,
            method,
            filter,
            params,
          )
        } else {
          const result = await client.call(method, { ...params })
          response = result?.data ?? result
        }

        if (response) {
          if (!Array.isArray(response)) {
            response = [response]
          }

          const retrievedIds = response.map((datum: BaseDocument) => datum._id)

          const promiseChunks = chunk(
            response.map((datum: BaseDocument) =>
              updateDocument(innerCollection, datum, filter),
            ),
            64,
          )

          for (const chunk of promiseChunks) {
            await Promise.all(chunk)
          }

          await cleanupUnusedDocuments(
            innerCollection,
            filter,
            retrievedIds,
            selectiveSync,
          )
        }
      } catch (error) {
        await innerCollection.deleteMany(filter)
        console.error({ method, ...error })
      } finally {
        setLoading(false)
      }
    },
    { wait: 1000, leading: true },
  )

  useDeepCompareEffect(() => {
    set(window, `collections.${innerCollection.name}`, innerCollection)
    refresh.run()
  }, [innerCollection, filter, params])

  useLocalEvent(
    {
      event: ClientEvents.INITIALIZED,
    },
    refresh.run,
  )

  useRemoteEvent(
    {
      event: HeleneEvents.METHOD_REFRESH,
      channel,
    },
    (refreshMethod: string) => {
      if (refreshMethod === method) refresh.run()
    },
    [refresh.run],
  )

  const data = useFind(innerCollection, filter, sort, projection)

  const result: any = useCreation(() => ({}), [])

  result.collection = innerCollection
  result.data = single ? data[0] : data
  result.loading = loading
  result.client = client
  result.refresh = refresh.run

  return result
}
