import { FunkyButton } from '@components/ui/funky-button'
import { IconFolderPlus, IconPlus } from '@tabler/icons-react'

export function EmptyState({
  title,
  description,
  buttonText = null,
  onClick = () => {},
  to = '',
  icon: Icon = IconFolderPlus,
}) {
  return (
    <div className='text-center'>
      <Icon className='mx-auto h-12 w-12 text-gray-400' aria-hidden='true' />
      <h3 className='mt-2 text-sm font-semibold text-gray-900 dark:text-slate-300'>
        {title}
      </h3>
      <p className='mt-1 text-sm text-gray-500 dark:text-slate-400'>
        {description}
      </p>
      {buttonText ? (
        <div className='mt-6'>
          <FunkyButton variant='fb' to={to} onClick={onClick}>
            <IconPlus className='-ml-0.5 mr-1.5 h-5 w-5' aria-hidden='true' />
            {buttonText}
          </FunkyButton>
        </div>
      ) : null}
    </div>
  )
}
