import { addSection, ClientSectionCollection } from '@/client/data'
import { useCount } from '@helenejs/react'
import { IconPlus } from '@tabler/icons-react'

export function DeckSectionAdder({ top = false }) {
  const sectionCount = useCount(ClientSectionCollection, {
    removedAt: { $exists: false },
  })

  return (
    <div className='flex items-center justify-center'>
      <button
        className='btn btn-square btn-ghost btn-sm'
        onClick={async () => {
          await addSection({
            top,
          })
        }}
        disabled={sectionCount >= 10}
      >
        <IconPlus className='h-4 w-4' />
      </button>
    </div>
  )
}
