import { ClientBoardNodeCommentCollection } from '@/client/data'
import { useBoardId } from '@components/boards/hooks/use-board-id'
import { useClient, useFind } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { t } from '@lingui/macro'
import { Button, Textarea } from '@mantine/core'
import { IconMessageCircle, IconSend, IconTrash } from '@tabler/icons-react'
import { ObjectId } from 'bson'
import React, { useState } from 'react'
import { EmptyState } from '../ui/empty-state'
import { useBoardState } from './hooks/use-board-state'

export function NodeComments({ nodeId }) {
  const client = useClient()
  const { user, userId } = useMetaboardAuth()
  const boardId = useBoardId()
  const [content, setContent] = useState('')

  const { isReadOnly } = useBoardState()

  const comments = useFind(
    ClientBoardNodeCommentCollection,
    {
      board: boardId,
      node: nodeId,
    },
    {
      createdAt: -1,
    },
  )

  const handleAddComment = async () => {
    const _content = content.trim()

    if (_content) {
      const _id = new ObjectId().toHexString()

      ClientBoardNodeCommentCollection.insert({
        _id,
        board: boardId,
        node: nodeId,
        content: _content,
        author: userId,
        authorName: user?.name,
      })

      client
        .call('boards.addNodeComment', {
          _id,
          boardId,
          nodeId,
          comment: {
            content,
          },
        })
        .catch(console.error)

      setContent('')
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleAddComment().catch(console.error)
    }
  }

  return (
    <div className='p-4'>
      <div className='mb-4'>
        {comments.length ? (
          comments.map(comment => (
            <Comment key={comment._id} comment={comment} />
          ))
        ) : (
          <EmptyState
            title={t`No comments yet`}
            description={t`Be the first to comment on this node`}
            icon={IconMessageCircle}
          />
        )}
      </div>
      {!isReadOnly && (
        <div className='flex items-center gap-2 pt-4'>
          <Textarea
            variant='filled'
            value={content}
            onChange={e => setContent(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={t`Add a comment`}
            minRows={1}
            autosize
            radius='sm'
            className='flex-grow'
          />
          <Button
            variant='light'
            color='blue'
            size='sm'
            onClick={handleAddComment}
            rightSection={<IconSend size={16} />}
            disabled={content.trim() === ''}
          >
            {t`Send`}
          </Button>
        </div>
      )}
    </div>
  )
}

function Comment({ comment }) {
  return (
    <div className='flex items-center justify-start p-4 even:bg-slate-500/10'>
      <div>
        <div className='mb-2 flex items-center justify-start gap-2 text-sm font-medium'>
          <img
            src='/profile-picture.png'
            alt={comment.authorName}
            className='my-0 h-8 w-8 rounded-full'
          />
          <span>{comment.authorName}</span>
        </div>
        <div className='flex-grow text-sm font-medium text-gray-300'>
          {comment.content}
        </div>
      </div>
      <ManageCommentSection comment={comment} />
    </div>
  )
}

function ManageCommentSection({ comment }) {
  const client = useClient()
  const { userId } = useMetaboardAuth()

  if (comment.author !== userId) return null

  return (
    <div className='ml-auto flex items-center gap-1'>
      <Button
        variant='light'
        color='red'
        size='xs'
        onClick={() => {
          ClientBoardNodeCommentCollection.deleteOne({
            _id: comment._id,
          })
          client
            .call('boards.deleteNodeComment', {
              _id: comment._id,
              boardId: comment.board,
              nodeId: comment.node,
            })
            .catch(console.error)
        }}
      >
        <IconTrash size={16} />
      </Button>
    </div>
  )
}
