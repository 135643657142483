import { MetaboardEvent } from '@/common/events'
import { CommandCenter } from '@components/boards/command-center'
import { useReactive } from 'ahooks'
import React from 'react'
import { object } from 'yup'
import { useClient } from '../client'
import { CompleteDetailsForm } from '../components/forms/complete-details-form'
import { StandardForm } from '../components/forms/standard-form'
import { useMetaboardAuth } from '../hooks/use-metaboard-auth'
import { SchemaHook } from '../hooks/use-schema'

export function Boards() {
  const state = useReactive({
    loading: false,
  })

  const client = useClient()
  const { incomplete } = useMetaboardAuth()

  const nameSchema = SchemaHook.useName()

  if (incomplete) {
    return (
      <StandardForm
        className='mx-auto max-w-3xl'
        initialValues={{ name: '' }}
        onSave={async ({ name }) => {
          state.loading = true
          client.call('user.update', { name }).catch(console.error)
          await client.waitFor(MetaboardEvent.USER_REFRESHED, 30000)
          state.loading = false
        }}
        schema={object({
          name: nameSchema,
        })}
      >
        <CompleteDetailsForm />
      </StandardForm>
    )
  }

  return <CommandCenter />
}
