import { useEffect } from 'react'

export function useWheel(
  target: any = document,
  onZoomIn: () => void,
  onZoomOut: () => void,
  deps: any[] = [],
) {
  useEffect(() => {
    if (!target) return

    const onWheel = (e: WheelEvent) => {
      e.preventDefault()
      e.stopPropagation()

      if (e.deltaY > 0) {
        onZoomOut()
      } else {
        onZoomIn()
      }
    }

    target.addEventListener('wheel', onWheel, { passive: false })

    return () => {
      target.removeEventListener('wheel', onWheel)
    }
  }, deps)
}
