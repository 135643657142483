import { IconLoader } from '@tabler/icons-react'
import classnames from 'classnames'
import React from 'react'

type SpinnerProps = {
  className?: string
}

export function Spinner({ className }: SpinnerProps) {
  return (
    <div
      className={classnames(
        'flex h-[24px] items-center justify-center',
        className,
      )}
    >
      <IconLoader className='h-4 w-4 animate-spin' />
    </div>
  )
}
