import { NodeType } from '@/common/constants/boards'
import { BaseColor } from '@/common/constants/color'
import { BoardOperations } from '@components/boards/hooks/use-board-operations'
import { VirtualBounds } from '@components/boards/hooks/use-virtual-bounds'
import { uploadBoardFile } from '@components/boards/utils/image'
import { getNodeDimensions } from '@components/boards/utils/nodes'
import {
  fromVirtualX,
  fromVirtualY,
} from '@components/boards/utils/virtualization'
import { Client } from '@helenejs/client'
import React from 'react'

export async function pasteWav({
  client,
  boardId,
  xRef,
  yRef,
  file,
  bounds,
  operations,
}: {
  client: Client
  boardId: string
  xRef: React.MutableRefObject<number>
  yRef: React.MutableRefObject<number>
  file: File
  bounds: VirtualBounds
  operations: BoardOperations
}) {
  const text = file.name

  const dimensions = getNodeDimensions({}, text)

  const nodeId = await operations.addNode(
    fromVirtualX(xRef.current, bounds),
    fromVirtualY(yRef.current, bounds),
    {
      type: NodeType.Audio,
      name: text,
      color: BaseColor.Blue,
      ...dimensions,
    },
  )

  const m4a = await uploadBoardFile(client, boardId, file, () => {
    operations.deleteNode(nodeId)
  })

  operations.updateNode(nodeId, {
    m4a,
  })
}
