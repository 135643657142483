import { useLevel } from '@/client/hooks/use-level'
import { registerServiceWorker } from '@/client/utils/register-service-worker'
import { LanguageCode } from '@/common/constants'
import { BoardWorkspace } from '@/common/constants/boards'
import { User } from '@/common/constants/users'
import { AdminEvent, MetaboardEvent } from '@/common/events'
import { Client } from '@helenejs/client'
import {
  useAuth,
  useClient,
  useLocalEvent,
  useObject,
  useRemoteEvent,
} from '@helenejs/react'
import { ClientEvents, TOKEN_HEADER_KEY } from '@helenejs/utils'
import { useHeleneEvent } from '@hooks/use-helene-event'
import { i18n } from '@lingui/core'
import { t, Trans } from '@lingui/macro'
import { Button } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { IconReload } from '@tabler/icons-react'
import { useLocalStorageState } from 'ahooks'
import useDebounceFn from 'ahooks/lib/useDebounceFn'
import axios from 'axios'
import defer from 'lodash/defer'
import noop from 'lodash/noop'
import React, { useCallback, useEffect, useState } from 'react'
import { singletonHook } from 'react-singleton-hook'
import { setLocale } from 'yup'
import { en, pt } from 'yup-locales'

declare global {
  interface Window {
    __ASKED_FOR_REFRESH: boolean
    __COMMIT_HASH__: string
    Helene: Client
  }

  let __COMMIT_HASH__: string
  let __ASKED_FOR_REFRESH: boolean
}

function useHashChange() {
  const client = useClient()

  const onUpdateDetected = useCallback(async () => {
    if ('serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.ready

      await registration.update()
    }

    notifications.show({
      title: `🎉 ${t`Update Available`} 🎉`,
      message: (
        <>
          <div className='mb-3'>
            <Trans>
              A new version of Metaboard is available. Please reload to get the
              latest updates.
            </Trans>
          </div>
          <div>
            <Button
              variant='outline'
              onClick={() => location.reload()}
              leftSection={<IconReload size={16} />}
            >
              <Trans>Reload</Trans>
            </Button>
          </div>
        </>
      ),
      color: 'orange',
      autoClose: false,
      withCloseButton: false,
    })
  }, [])

  useLocalEvent(
    {
      event: ClientEvents.INITIALIZED,
    },
    async () => {
      const { token } = client.context

      if (token) {
        axios.defaults.headers.common = {
          [TOKEN_HEADER_KEY]: token,
        }
      }

      const hash = await client.call('app.commitHash')

      console.log(`Commit Hash: ${__COMMIT_HASH__}`, hash)

      // @ts-ignore
      if (import.meta.env.MODE === 'development') return
      if (window.__ASKED_FOR_REFRESH) return

      if (__COMMIT_HASH__ && hash !== __COMMIT_HASH__) {
        onUpdateDetected().catch(console.error)

        window.__ASKED_FOR_REFRESH = true
      }
    },
    [onUpdateDetected],
  )
}

function useCurrentUser() {
  const client = useClient()

  const [loading, setLoading] = useState(true)
  const [user, setUser] = useLocalStorageState<any>('current:user')

  const refresh = useDebounceFn(
    async () => {
      if (!client.context.token) {
        setUser(null)
        setLoading(false)
        return
      }

      if (!client.initialized) {
        console.log('waiting for client to initialize')
        await client.waitFor(ClientEvents.INITIALIZED)
      }

      setLoading(true)
      console.log('refreshing user')
      let user = await client.call('user.current')

      /**
       * Workaround for user not being found after reconnection from idle disconnect
       */
      if (!user && client.context.token && client.context.userId) {
        console.log('Sapienza: User not found, attempting to initialize again')
        await client.initialize()
        user = await client.call('user.current')

        if (!user) {
          console.error('Sapienza: User not found', client.context.userId)
        }
      }

      console.log('refreshed user', user ? user._id : user)
      setUser(user)
      setLoading(false)

      defer(() => {
        client.emit(MetaboardEvent.USER_REFRESHED, user)
      })
    },
    {
      wait: 1000,
      trailing: true,
      leading: false,
    },
  )

  useRemoteEvent(
    {
      event: MetaboardEvent.USER_CHANGED,
      channel: user?._id,
      active: !!user,
    },
    refresh.run,
    [user],
  )

  useHeleneEvent(MetaboardEvent.USER_REFRESH, refresh.run)
  useHeleneEvent(ClientEvents.LOGOUT, refresh.run)
  useHeleneEvent(ClientEvents.CONTEXT_CHANGED, refresh.run)
  useHeleneEvent(ClientEvents.INITIALIZED, refresh.run)

  return useObject({ user, loading: !user && loading })
}

type MetaboardAuth = {
  authenticated: boolean
  client: Client
  context: Record<string, any>
  isAdmin: boolean
  loading: boolean
  ready: boolean
  refresh: () => void
  setContext: (context: Record<string, any>) => void
  user: User<string>
  userId: string
  isPremiumActive: boolean
  isSuperAdmin: boolean
  updating: boolean
  incomplete: boolean
  isChatOpen: boolean
  recordingMode: boolean
  currentWorkspace: string
  setCurrentWorkspace: (id: string) => void
}

export const useMetaboardAuth = singletonHook<MetaboardAuth>(
  {
    authenticated: false,
    client: null,
    context: null,
    isAdmin: false,
    loading: true,
    ready: false,
    refresh: noop,
    setContext: noop,
    user: null,
    userId: null,
    isPremiumActive: false,
    isSuperAdmin: false,
    updating: false,
    incomplete: false,
    isChatOpen: false,
    recordingMode: false,
    currentWorkspace: BoardWorkspace.All,
    setCurrentWorkspace: noop,
  },
  () => {
    const auth = useAuth()
    const client = useClient()

    // @ts-ignore
    window.Helene = client

    useHashChange()

    const { user, loading: loadingUser } = useCurrentUser()

    useEffect(() => {
      registerServiceWorker(client)
    }, [])

    const authenticated = auth.authenticated && !!user

    useRemoteEvent(
      { event: AdminEvent.USER_REGISTERED, active: user?.isAdmin },
      email => {
        client.emit(MetaboardEvent.BROWSER_NOTIFICATION, {
          title: t`New User Registered`,
          body: email,
        })
      },
      [user],
    )

    useLocalEvent(
      {
        event: MetaboardEvent.BROWSER_NOTIFICATION,
      },
      ({ title, body }) => {
        if (Notification.permission === 'granted') {
          new Notification(title, {
            body,
            icon: '/favicon.svg',
          })
        }
      },
    )

    useEffect(() => {
      if (!user?.language) return
      i18n.activate(user.language)
      document.documentElement.lang = user.language

      if (user.language === LanguageCode.PT) {
        setLocale(pt)
      } else {
        setLocale(en)
      }
    }, [user?.language])

    const [isChatOpen, setChatOpen] = useState(false)

    useLocalEvent(
      {
        event: MetaboardEvent.CHAT_TOGGLE,
      },
      () => {
        setChatOpen(!isChatOpen)
      },
      [isChatOpen],
    )

    const [recordingMode, setRecordingMode] = useLocalStorageState(
      'metaboard:recording:mode',
      {
        defaultValue: false,
      },
    )

    useHeleneEvent(
      MetaboardEvent.RECORDING_MODE_TOGGLE,
      () => {
        setRecordingMode(!recordingMode)
      },
      [recordingMode],
    )

    const levelData = useLevel()

    const [currentWorkspace, setCurrentWorkspace] = useLocalStorageState(
      'current:workspace',
      {
        defaultValue: BoardWorkspace.All,
      },
    )

    return useObject({
      ...auth,
      currentWorkspace,
      setCurrentWorkspace,
      authenticated: auth.authenticated && !!user,
      loading: loadingUser,
      isAdmin: Boolean(user?.isAdmin),
      isSuperAdmin: Boolean(user?.isSuperAdmin),
      user,
      userId: user?._id,
      isPremiumActive: user?.isPremiumActive ?? false,
      levelData,
      incomplete: authenticated && user && !user.name,
      isChatOpen,
      recordingMode,
    }) as MetaboardAuth
  },
)
