import {
  ClientBoardNodeCollection,
  ClientBoardNodeCommentCollection,
} from '@/client/data'
import { NodeType } from '@/common/constants/boards'
import { NodeComments } from '@components/boards/node-comments'
import { NodeImage } from '@components/boards/node-image'
import { NodeAIChat } from '@components/boards/nodes/view/node-ai-chat'
import { NodeCode } from '@components/boards/nodes/view/node-code'
import { NodeContact } from '@components/boards/nodes/view/node-contact'
import { NodeFlashcardsCreator } from '@components/boards/nodes/view/node-flashcards-creator'
import { NodeMarkdown } from '@components/boards/nodes/view/node-markdown'
import { NodePdfViewer } from '@components/boards/nodes/view/node-pdf-viewer'
import { NodeTextEditor } from '@components/boards/nodes/view/node-text-editor'
import { unescapeHtml } from '@components/boards/utils/unescape-html'
import { Drawer } from '@components/ui/drawer'
import { useCount, useFindOne } from '@helenejs/react'
import { Tabs } from '@mantine/core'
import React from 'react'

function NodeComponent({ node }) {
  switch (node.type) {
    case NodeType.Code:
      return <NodeCode node={node} />
    case NodeType.AIChat:
      return <NodeAIChat node={node} />
    case NodeType.Contact:
      return <NodeContact node={node} />
    case NodeType.Flashcards:
      return <NodeFlashcardsCreator node={node} />
    case NodeType.PDF:
      return <NodePdfViewer node={node} />
    case NodeType.Markdown:
      return <NodeMarkdown node={node} />
    default:
      return <NodeTextEditor node={node} />
  }
}

export function NodeDrawer({ isOpen, onClose, id, onFinishedClosing }) {
  const node = useFindOne(ClientBoardNodeCollection, { _id: id })

  const comments = useCount(ClientBoardNodeCommentCollection, {
    board: node?.board,
    node: id,
  })

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      title={node?.name ? unescapeHtml(node.name) : null}
      loading={!node}
      onFinishedClosing={onFinishedClosing}
      className='p-0'
    >
      <Tabs
        defaultValue='content'
        variant='default'
        radius='none'
        className='flex h-full flex-col'
      >
        <Tabs.List>
          <Tabs.Tab value='content' className='text-sm font-medium'>
            Content
          </Tabs.Tab>
          <Tabs.Tab value='comments' className='text-sm font-medium'>
            Comments ({comments})
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='content' className='flex-grow'>
          <NodeComponent node={node} />
          <div className='px-4'>
            {node?.textDetections ? <NodeImage node={node} /> : null}
          </div>
        </Tabs.Panel>

        <Tabs.Panel value='comments'>
          <NodeComments nodeId={id} />
        </Tabs.Panel>
      </Tabs>
    </Drawer>
  )
}
