import { useClient } from '@/client/client'
import { ClientBoardCollection } from '@/client/data'
import { SimpleNode } from '@components/boards/simple-node'
import { TimedLoader } from '@components/timed-loader'
import { useFind } from '@helenejs/react'
import { useTheme } from '@hooks/use-theme'
import { t } from '@lingui/macro'
import { useDebouncedCallback } from '@mantine/hooks'
import { notifications } from '@mantine/notifications'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo, useState } from 'react'
import CommandPalette, {
  JsonStructure,
  filterItems,
  renderJsonStructure,
  useHandleOpenCommandPalette,
} from 'react-cmdk'
import 'react-cmdk/dist/cmdk.css'
import { useHistory } from 'react-router-dom'

export enum CommandPalettePage {
  Root = 'root',
  SearchNode = 'search-node',
}

export const Search = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [search, setSearch] = useState('')
  const history = useHistory()
  const { toggle, isDark } = useTheme()
  const [loading, setLoading] = useState(false)
  const [nodes, setNodes] = useState([])
  const client = useClient()

  const boards = useFind(ClientBoardCollection, {}, { name: 1 }, { name: 1 })

  const [page, setPage] = useState<CommandPalettePage>(CommandPalettePage.Root)

  const submit = useDebouncedCallback(async () => {
    if (page !== CommandPalettePage.SearchNode) {
      return
    }

    if (!search) {
      setPage(CommandPalettePage.Root)
      setNodes([])
      return
    }

    try {
      setLoading(true)
      const result = await client.m.boards.searchNodesText({
        query: search,
      })

      if (result.hits.length === 0) {
        notifications.show({
          title: 'No Results',
          message: 'No nodes found for your search query.',
          color: 'yellow',
        })
      }

      setNodes(result.hits)
    } catch (e) {
      console.error(e)
      notifications.show({
        title: 'Error',
        message: 'Failed to search nodes. Please try again later.',
        color: 'red',
      })
    } finally {
      setLoading(false)
    }
  }, 500)

  useEffect(submit, [search, submit])

  const filteredItems = filterItems(
    [
      {
        heading: 'Home',
        id: 'home',
        items: [
          {
            id: 'boards',
            children: t`Home`,
            icon: 'HomeIcon',
            onClick: () => {
              setOpen(false)
              history.push('/')
            },
          },
          {
            id: 'account-settings',
            children: t`Account Settings`,
            icon: 'CogIcon',
            onClick: () => {
              setOpen(false)
              history.push('/settings/account')
            },
          },
          {
            id: 'plan-&-billing',
            children: t`Plan & Billing`,
            icon: 'CogIcon',
            onClick: () => {
              setOpen(false)
              history.push('/plan')
            },
          },
          {
            id: 'search-nodes',
            children: t`Search Nodes`,
            icon: 'ViewfinderCircleIcon',
            closeOnSelect: false,
            onClick() {
              setSearch('')
              setPage(CommandPalettePage.SearchNode)
            },
          },
        ],
      },
      {
        heading: t`Boards`,
        id: 'boards',
        items: boards.map(board => ({
          id: board._id,
          children: board.name,
          icon: 'Squares2X2Icon',
          onClick: () => {
            setOpen(false)
            history.push(`/b/${board._id}`)
          },
        })),
      },
      {
        heading: 'Other',
        id: 'advanced',
        items: [
          {
            id: 'theme',
            children: `Theme (${isDark ? 'Dark' : 'Light'})`,
            icon: 'LifebuoyIcon',
            onClick: () => {
              toggle()
            },
          },
        ],
      },
    ],
    search,
  )

  const nodeItems = useMemo<JsonStructure>(() => {
    if (isEmpty(nodes)) {
      return []
    }

    return [
      {
        heading: 'Nodes',
        id: 'nodes',
        items: nodes.map(node => ({
          id: node._id,
          children: <SimpleNode node={node} />,
          icon: 'ChevronRightIcon',
          onClick: () => {
            history.push(`/b/${node.board}/n/${node._id}`)
          },
          name: node.name,
        })),
      },
    ]
  }, [nodes, history])

  useHandleOpenCommandPalette(setOpen)

  return (
    <CommandPalette
      onChangeSearch={setSearch}
      onChangeOpen={open => {
        setOpen(open)
      }}
      search={search}
      isOpen={open}
      page={page}
    >
      <CommandPalette.Page id={CommandPalettePage.Root}>
        {renderJsonStructure(filteredItems)}
      </CommandPalette.Page>

      <CommandPalette.Page id={CommandPalettePage.SearchNode}>
        {loading ? (
          <TimedLoader label='Searching...' />
        ) : (
          renderJsonStructure(nodeItems)
        )}
      </CommandPalette.Page>
    </CommandPalette>
  )
}
