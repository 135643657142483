import { MyDeckLearnButton } from '@/client/components/decks/my-deck-learn-button'
import { FunkyButton } from '@/client/components/ui/funky-button'
import { AudioUtils } from '@/client/utils/audio-utils'
import { MetaboardEvent } from '@/common/events'
import { useClient } from '@helenejs/react'
import { useMetaboardAuth } from '@hooks/use-metaboard-auth'
import { Trans } from '@lingui/macro'
import { IconPlayerPlay } from '@tabler/icons-react'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

export function EnrollButton({ isDeckEnrolled, deckId, className }) {
  const [isEnrolling, setEnrolling] = useState(false)

  const client = useClient()
  const { user, setEnrollingId } = useMetaboardAuth()
  const history = useHistory()

  if (!user)
    return (
      <FunkyButton
        to={`/sign-in`}
        variant='primary'
        onClick={() => {
          AudioUtils.unlock()
          setEnrollingId(deckId)
        }}
        className={className}
      >
        <IconPlayerPlay className='h-4 w-4' /> <Trans>Start Now</Trans>
      </FunkyButton>
    )

  if (isDeckEnrolled) {
    return (
      <MyDeckLearnButton
        deckId={deckId}
        variant='primary'
        className={className}
      />
    )
  }

  return (
    <FunkyButton
      variant='primary'
      onClick={async () => {
        AudioUtils.unlock()

        setEnrolling(true)

        await client.call('deck.enroll', { deckId })

        client.emit(MetaboardEvent.USER_REFRESH)

        history.push(`/practice/${deckId}`)
      }}
      loading={isEnrolling}
      className={className}
    >
      <IconPlayerPlay className='h-4 w-4' /> <Trans>Start Now</Trans>
    </FunkyButton>
  )
}
