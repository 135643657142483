import { ClientBoardNodeCollection } from '@/client/data'
import { fetchImageWithAuth } from '@/client/utils/fetch-image-with-auth'
import { BoardNode, NodeType } from '@/common/constants/boards'
import { useClient } from '@helenejs/react'
import { useEffect, useState } from 'react'

export function useImageUrl(node: BoardNode<string>) {
  const client = useClient()
  const [imageUrl, setImageUrl] = useState(null)

  useEffect(() => {
    if (node.type === NodeType.Image) {
      if (node.image) {
        fetchImageWithAuth(
          client,
          `board/${node.board}/image/${node.image}`,
        ).then(url => {
          setImageUrl(url)
          ClientBoardNodeCollection.updateOne(
            {
              _id: node._id,
            },
            {
              $set: {
                _temporaryUrl: null,
              },
            },
          )
        })

        return
      }

      if (node._temporaryUrl) {
        return setImageUrl(node._temporaryUrl)
      }

      return setImageUrl(null)
    } else {
      setImageUrl(null)
    }
  }, [node.type, node.image, node._temporaryUrl])

  return imageUrl
}
