import { addExercise, ClientExerciseCollection } from '@/client/data'
import { useCount } from '@helenejs/react'
import { IconPlus } from '@tabler/icons-react'
import React from 'react'

export function DeckExerciseAdder({ section, top = false }) {
  const exerciseCount = useCount(ClientExerciseCollection, {
    section: section._id,
    removedAt: { $exists: false },
  })

  return (
    <div className='flex items-center justify-center'>
      <button
        className='btn btn-square btn-ghost btn-sm'
        onClick={async () => {
          await addExercise({
            section,
            top,
          })
        }}
        disabled={exerciseCount >= 100}
      >
        <IconPlus className='h-4 w-4' />
      </button>
    </div>
  )
}
